import React from "react";
import { Button } from "reactstrap";

function MyButton({
  icon,
  label,
  onClick,
  selected,
  color,
  disabled,
  className,
  fontWeight,
  btnType,
}) {
  let colorButton = {
    "": "bg-gray-600 text-white",
    success: "bg-green-600 text-white",
    secondary: "bg-gray-600 text-white",
    danger: "bg-red-600 text-white",
    info: "bg-blue-600 text-white",
    none: "bg-gray-600 text-white",
    online: "bg-lime-600 text-white",
    warning: "bg-red-600 text-white",
    light: "bg-gray-100 text-gray-800",
    primary: "bg-orange-600 text-white",
    Download: "bg-red-600 text-white hover:bg-red-600",
    Activated: "bg-red-50 text-red-600 hover:bg-red-600 hover:text-white",
  };

  return (
    <Button
      className={
        "Button flex items-center " +
        (disabled ? "inactive" : color ? colorButton[color] : "") +
        (className ? " " + className : "")
      }
      disabled={disabled}
      onClick={onClick}
      style={{
        paddingRight: 10,
        paddingLeft: 10,
        marginRight: 4,
        marginLeft: 4,
        marginBottom: 8,
        backgroundColor: color ? "" : "#e12029",
        borderRadius: 6,
        fontWeight: fontWeight ? fontWeight : 700,
      }}
      type={btnType ? btnType : "submit"}
    >
      {icon && (
        <span
          style={{
            marginRight: 8,
            marginBottom: 2,
          }}
        >
          {icon}
        </span>
      )}
      {label}
    </Button>
  );
}

export default MyButton;
