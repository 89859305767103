import Pill from "../../../../components/Pill";
import {
  dateFormatter,
  toSentenceCase,
  ageFromBirthdate,
  dateTimeFormatterstriped,
} from "../../../../utils";
import { keyColorMembership } from "./statics";

export const membershipColumns = [
  {
    Header: "Resident",
    accessor: (row) => (
      <div className="flex flex-col gap-y-1">
        <h1 className="font-bold text-[14px] capitalize">{row.name}</h1>
        <h1 className="text-[12px] text-gray-400">
          {row.phone ? "+" + row.phone : "-"}
        </h1>
      </div>
    ),
  },
  {
    Header: "Package",
    accessor: (row) => (
      <div className="flex flex-col gap-y-1">
        <h1 className="font-bold text-[14px]">{row.package_name}</h1>
        <h1 className="text-[12px] text-gray-400">{row.package_period} Days</h1>
      </div>
    ),
  },
  {
    Header: "Facility",
    accessor: (row) => <b>{row.facility_name}</b>,
  },
  {
    Header: "Card Number",
    accessor: (row) => row.card_number,
  },
  {
    Header: "Status",
    accessor: (row) => (
      <Pill color={keyColorMembership[row.status]}>
        {toSentenceCase(row.status)}
      </Pill>
    ),
  },
  {
    Header: "Expired Date",
    accessor: (row) => {
      return <b>{row.expired_date ? dateFormatter(row.expired_date) : "-"}</b>;
    },
  },
];
