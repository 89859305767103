import { IoIosArrowDroprightCircle } from "react-icons/io";
import { BiHelpCircle } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal";
import Input from "../../../../form/inputBooking";
import MyButton from "../../../../../components/Button";
import { Form, Formik } from "formik";
import {
  createMembership,
  setModalMembership,
  setModalReset,
  updateDataMembership,
} from "../../../../slices/membership";
import { ModalBody, Modal as ModalReact } from "reactstrap";
import MembershipHelp from "../../../../../assets/membership_help.png";
import { TabForm } from "../TabForm";
import {
  endpointAdmin,
  endpointBookingFacility,
  endpointResident,
} from "../../../../../settings";
import { get, post } from "../../../../slice";
import { toast } from "sonner";
import { toMoneyRP } from "../../../../../utils";

const ModalCreateMembership = () => {
  const { user, role } = useSelector((state) => state.auth);
  const { selected, formPayloadMembership } = useSelector(
    (state) => state.membership
  );
  const { addMembership, help, editMembership } = useSelector(
    (state) => state.membership.modal
  );
  const dispatch = useDispatch();
  const [tabForm, setTabForm] = useState(1);
  const [buildings, setBuildings] = useState([]);
  const [facilites, setFacilities] = useState([]);
  const [nullFacility, setNullFacility] = useState(false);
  const [packages, setPackages] = useState([]);
  const [dataPackages, setDataPackages] = useState();
  const [formPayload, setFormPayload] = useState(formPayloadMembership);
  const [selectedBuilding, setSelectedBuilding] = useState(
    role !== "sa" ? user?.building_id : formPayloadMembership?.building_id
  );
  const [selectedFacility, setSelectedFacility] = useState(
    formPayloadMembership?.facility_id
  );

  // Double Check that formPayloadMembership is not a falsy
  useEffect(() => {
    if (formPayloadMembership) {
      setSelectedBuilding(formPayloadMembership.building_id || "");
      setSelectedFacility(formPayloadMembership.facility_id || "");
      setFormPayload(formPayloadMembership);
    }
  }, [formPayloadMembership]);

  // Building Options
  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/management/building" +
          "?limit=10&page=1" +
          "&search=",
        (res) => {
          let buildings = res.data.data.items;

          let formatted = buildings.map((el) => ({
            label: el.building_name,
            value: el.building_id.toString(),
          }));

          setBuildings(formatted);
        }
      )
    );
  }, [dispatch]);

  // Facility Options based on Building ID
  useEffect(() => {
    dispatch(
      get(
        endpointBookingFacility +
          "/admin/facilities?building_id=" +
          selectedBuilding,
        (res) => {
          let packages = res.data.data.items;

          if (!packages) {
            setNullFacility(false);
            toast.error(
              `Building doesn't have facility, please select other building`
            );
          } else {
            let formatted = packages.map((el) => ({
              label: el.name,
              value: el.id.toString(),
            }));
            setNullFacility(true);
            setFacilities(formatted);
          }
        }
      )
    );
  }, [selectedBuilding, dispatch, formPayloadMembership]);

  // Package Member Options based on Facility
  useEffect(() => {
    dispatch(
      get(
        endpointBookingFacility +
          "/admin/facilities/" +
          selectedFacility +
          "/packages",
        (res) => {
          let packages = res.data.data;

          if (packages.length > 0) {
            let formatted = packages.map((el) => ({
              label: el.name,
              value: el.id.toString(),
            }));

            setDataPackages(packages);
            setPackages(formatted);
          } else {
            toast.error(`This facility doesn't have packages`);
          }
        }
      )
    );
  }, [dispatch, selectedFacility, formPayloadMembership]);

  // Handle ToggleOff
  const handleToggleOff = () => {
    dispatch(setModalReset());
    setFormPayload({
      facility_id: "",
      package_id: "",
      email: "",
      phone: "",
      firstname: "",
      lastname: "",
      card_number: "",
      building_id: null,
    });
    setPackages([]);
    setFacilities([]);
    setSelectedBuilding(null);
    setSelectedFacility(null);
    setTabForm(1);
  };

  return (
    <>
      {/* Modal Create Membership */}
      <Modal
        isOpen={addMembership || editMembership}
        toggle={handleToggleOff}
        title={addMembership ? "Add Member" : "Edit Member"}
        disableFooter={true}
        closeTrigger={
          <div
            onClick={() => {
              dispatch(setModalReset());
              dispatch(setModalMembership({ help: true }));
              setTabForm(1);
            }}
            className="flex gap-x-1 items-center justify-center hover:text-red-600 transition-all cursor-pointer"
          >
            <BiHelpCircle /> <h1>Help</h1>
          </div>
        }
      >
        {tabForm == 1 && (
          <Formik
            initialValues={formPayload}
            autoComplete={"off"}
            onSubmit={(values, actions) => {
              // Checking Member Validation
              let checkValidation = { ...values, phone: "62" + values.phone };

              if (editMembership) {
                checkValidation = {
                  id: formPayloadMembership.resident_id,
                  email: formPayloadMembership.email,
                  phone: formPayloadMembership.phone,
                };
              }

              dispatch(
                post(
                  endpointResident + "/members/validate",
                  checkValidation,
                  (res) => {
                    toast.success("Member is Valid to Register");
                    let create =
                      role !== "sa"
                        ? {
                            ...formPayload,
                            building_id: user.building_id,
                          }
                        : {
                            ...formPayload,
                          };

                    Object.keys(values).forEach((el) => {
                      if (
                        values[el] !== undefined &&
                        values[el] !== null &&
                        values[el] !== ""
                      ) {
                        create[el] = values[el];
                      }
                    });

                    setFormPayload(create);
                    setTabForm(2);
                  },
                  (err) => {
                    toast.error(err?.response?.data?.error);
                  }
                )
              );
            }}
          >
            {(props) => {
              const { values, setFieldValue, errors, handleSubmit } = props;

              return (
                <Form>
                  <div className="px-3 flex flex-col gap-y-5 items-start py-8">
                    <div className="w-full px-4 pt-3">
                      <TabForm tabForm={tabForm} setTabForm={setTabForm} />
                    </div>

                    {role === "sa" && (
                      <Input
                        {...props}
                        autoComplete="off"
                        label="Building"
                        className={
                          "outline-none focus:outline-red-600 cursor-pointer"
                        }
                        placeholder={"Select Building"}
                        name="building_id"
                        onChange={(e) => {
                          setFieldValue("building_id", parseInt(e.value));
                          setSelectedBuilding(e.value);
                        }}
                        options={buildings}
                        star={true}
                      />
                    )}
                    <Input
                      {...props}
                      label="Email"
                      name="email"
                      autoComplete="off"
                      className={"outline-none focus:outline-red-600"}
                      placeholder={"Enter email"}
                      onChange={(e) => setFieldValue("email", e)}
                      star={true}
                    />
                    <div className="relative w-full">
                      <Input
                        {...props}
                        className={"pl-16 outline-none focus:outline-red-600"}
                        label="Phone Number"
                        name="phone"
                        autoComplete="off"
                        onChange={(e) => setFieldValue("phone", e)}
                        star={true}
                      />
                      <h1 className="absolute font-bold left-5 top-[3.7rem]">
                        +62
                      </h1>
                    </div>
                    <div className="flex gap-x-3 flex-1 w-full">
                      <Input
                        {...props}
                        label="First Name"
                        autoComplete="off"
                        name="firstname"
                        className={"outline-none focus:outline-red-600 w-full"}
                        placeholder={"Enter firstname"}
                        onChange={(e) => setFieldValue("firstname", e)}
                        star={true}
                      />
                      <Input
                        {...props}
                        label="Last Name"
                        autoComplete="off"
                        name="lastname"
                        className={"outline-none focus:outline-red-600 w-full"}
                        placeholder={"Enter lastname"}
                        onChange={(e) => setFieldValue("lastname", e)}
                      />
                    </div>
                  </div>

                  <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                    <MyButton
                      label={"Back"}
                      btnType={"button"}
                      onClick={() => handleToggleOff()}
                      color={"secondary"}
                    />
                    <MyButton
                      label={"Next"}
                      btnType={"submit"}
                      // disabled={
                      //   !values.building_id || !nullFacility ? true : false
                      // }
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}

        {tabForm == 2 && (
          <Formik
            initialValues={formPayload}
            autoComplete={"off"}
            onSubmit={(values, actions) => {
              let create = {
                ...formPayload,
              };
              Object.keys(values).forEach((el) => {
                if (
                  values[el] !== undefined &&
                  values[el] !== null &&
                  values[el] !== ""
                ) {
                  create[el] = values[el];
                }
              });
              setFormPayload(create);
              setTabForm(3);
            }}
          >
            {(props) => {
              const { values, setFieldValue, errors, handleSubmit } = props;

              return (
                <Form>
                  <div className="px-2.5 flex flex-col gap-y-5 items-start py-8 transition-all">
                    <div className="w-full px-4 pt-3">
                      <TabForm tabForm={tabForm} setTabForm={setTabForm} />
                    </div>

                    <Input
                      {...props}
                      label="Facility"
                      name="facility_id"
                      autoComplete="off"
                      options={facilites}
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                      placeholder={"Select facility"}
                      onChange={(e) => {
                        setFieldValue("facility_id", e.value);
                        setFieldValue("package_id", null);
                        setSelectedFacility(e.value);
                      }}
                      star={true}
                      type="select"
                    />

                    {values.facility_id && packages.length > 0 && (
                      <Input
                        {...props}
                        label="Package Member"
                        star={true}
                        options={packages}
                        autoComplete="off"
                        name="package_id"
                        placeholder={"Select package"}
                        className={
                          "outline-none focus:outline-red-600 cursor-pointer"
                        }
                        onChange={(e) =>
                          setFieldValue("package_id", parseInt(e.value))
                        }
                      />
                    )}
                    <Input
                      {...props}
                      label="Card Number"
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                      autoComplete="off"
                      onClick={(e) => e.target.select()}
                      name={"card_number"}
                      placeholder={"Tap the card on the card reader"}
                      setInputValue={(e) => setFieldValue("card_number", e)}
                      star={true}
                      onKeyPress={(e) =>
                        e.key === "Enter" && e.preventDefault()
                      }
                    />
                  </div>

                  <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                    <MyButton
                      label={"Back"}
                      btnType={"button"}
                      onClick={() => setTabForm(1)}
                      color={"secondary"}
                    />
                    <MyButton
                      label={"Next"}
                      btnType={"submit"}
                      disabled={!values.package_id ? true : false}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}

        {tabForm == 3 && (
          <Formik
            initialValues={formPayload}
            autoComplete={"off"}
            onSubmit={(values, actions) => {
              let payload =
                role === "bm"
                  ? {
                      ...values,
                      phone: "62" + values.phone,
                      building_id: user?.building_id,
                    }
                  : { ...values, phone: "62" + values.phone };

              editMembership
                ? dispatch(updateDataMembership(selected.id, values))
                : dispatch(createMembership(payload));
              handleToggleOff();
            }}
          >
            {(props) => {
              const { values, setFieldValue, errors, handleSubmit } = props;

              let dataDetailFacility = dataPackages.filter((data) => {
                return data.id == values.package_id;
              });

              return (
                <Form>
                  <div className="px-2.5 flex flex-col gap-y-2 items-start py-8">
                    <div className="w-full px-4 pt-3">
                      <TabForm tabForm={tabForm} setTabForm={setTabForm} />
                    </div>

                    <div className="w-full flex flex-col gap-y-2 p-[1rem] rounded-xl items-start">
                      <h1 className="text-left font-bold">User Information</h1>

                      <div className="flex flex-col gap-y-4 w-full pt-2">
                        <div className="flex justify-between">
                          <h1>Fullname</h1>
                          <h1>
                            {formPayload?.firstname} {formPayload?.lastname}
                          </h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Email</h1>
                          <h1>{formPayload?.email}</h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Phone Number</h1>
                          <h1>+{formPayload?.phone}</h1>
                        </div>
                      </div>
                    </div>

                    <div className=" w-full px-[1.5rem]">
                      <div className="bg-gray-300 h-[1px]"></div>
                    </div>

                    <div className="w-full flex flex-col gap-y-2 p-[1rem] rounded-xl items-start">
                      <h1 className="text-left font-bold">
                        Membership Information
                      </h1>

                      <div className="flex flex-col gap-y-4 w-full pt-2">
                        <div className="flex justify-between">
                          <h1>Facility</h1>
                          <h1>{formPayload?.facility_id_label}</h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Package Member</h1>
                          <h1>{dataDetailFacility?.at(0)?.name}</h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Membership Period</h1>
                          <h1>
                            {dataDetailFacility?.at(0)?.member_period} days
                          </h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Membership Price</h1>
                          <h1>
                            Rp
                            {toMoneyRP(
                              parseInt(dataDetailFacility?.at(0)?.price)
                            )}
                          </h1>
                        </div>
                        <div className="flex justify-between">
                          <h1>Card Number</h1>
                          <h1>{formPayload?.card_number}</h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                    <MyButton
                      label={"Back"}
                      btnType={"button"}
                      onClick={() => setTabForm(2)}
                      color={"secondary"}
                    />
                    <MyButton label={"Submit"} btnType={"submit"} />
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </Modal>

      {/* Modal Help */}
      <ModalReact
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
        isOpen={help}
        toggle={() => {
          dispatch(setModalReset());
          dispatch(setModalMembership({ addMembership: true }));
        }}
      >
        <ModalBody>
          <div className="flex flex-col w-full justify-center items-center h-full py-8 px-4 gap-y-1">
            <img src={MembershipHelp} className="size-24" />
            <div className="flex flex-col gap-y-4 w-full items-center mt-4">
              <h1 className="text-[18px] font-bold">
                Informasi Seputar Add Member
              </h1>
              <div className="flex gap-x-2 items-start relative pl-10">
                <IoIosArrowDroprightCircle className="absolute size-7 left-0 top-1 text-red-600" />
                <p className="text-left leading-8">
                  User yang ditambahkan saat Add Member akan otomatis
                  didaftarkan sebagai user Yipy.
                </p>
              </div>
              <div className="flex gap-x-2 items-start relative pl-10">
                <IoIosArrowDroprightCircle className="absolute size-7 left-0 top-1 text-red-600" />
                <p className="text-left leading-8">
                  User yang datanya telah terdaftar sebagai Premium User di Yipy
                  tidak dapat didaftarkan sebagai member.
                </p>
              </div>
            </div>
          </div>

          <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
            <MyButton
              label={"Back"}
              btnType={"button"}
              onClick={() => {
                dispatch(setModalReset());
                dispatch(setModalMembership({ addMembership: true }));
              }}
              color={"secondary"}
            />
          </div>
        </ModalBody>
      </ModalReact>
    </>
  );
};

export default ModalCreateMembership;
