import React from "react";

export const TabForm = ({ tabForm, setTabForm }) => {
  const tabDone =
    "size-12 rounded-full bg-red-600 flex items-center justify-center text-white outline outline-[5px] outline-red-100 transition-all duration-500";

  const tabInactive =
    "size-12 rounded-full bg-white flex items-center justify-center text-gray-300 border-[1px] border-gray-300 transition-all duration-500";

  const tabActive =
    "size-12 rounded-full bg-red-50 flex items-center justify-center text-red-600 outline outline-[2px] outline-red-600 transition-all duration-500";

  return (
    <div className="flex gap-x-3 w-full mb-4 pl-2 relative items-center justify-between">
      <div className="flex flex-col items-center gap-y-3 z-[2]">
        <div
          // onClick={() => setTabForm(1)}
          className={`${tabForm == 1 ? tabDone : tabDone}`}
        >
          1
        </div>
        <div className="text-[13px] text-gray-600">User Info</div>
      </div>
      <div className="flex flex-col items-center gap-y-3 z-[2]">
        <div
          // onClick={() => setTabForm(2)}
          className={`${
            tabForm == 2 ? tabActive : tabForm > 2 ? tabDone : tabInactive
          }`}
        >
          2
        </div>
        <div
          className={`${
            tabForm < 2 ? " text-gray-300" : "text-gray-600"
          } text-[13px] `}
        >
          Membership Info
        </div>
      </div>
      <div className="flex flex-col items-center gap-y-3 z-[2]">
        <div
          // onClick={() => setTabForm(3)}
          className={`${
            tabForm == 3 ? tabActive : tabForm > 3 ? tabDone : tabInactive
          }`}
        >
          3
        </div>
        <div
          className={`${
            tabForm < 3 ? " text-gray-300" : "text-gray-600"
          } text-[13px]`}
        >
          Confirmation
        </div>
      </div>

      {/* Border line */}
      <div
        className={`absolute h-[2px] bg-red-600 mx-8 top-6 transition-all ${
          tabForm == 1 ? "" : tabForm == 2 ? "w-[12rem]" : "w-[25rem]"
        } `}
      ></div>
      <div className="absolute h-[1px] bg-gray-300 w-[25rem] mx-8 top-6 transition-all"></div>
    </div>
  );
};
