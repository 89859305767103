import React from "react";
import { useRouteMatch, Switch, Route, Redirect } from "react-router-dom";

import List from "../../list/TokenThera";
import TheraSettlement from "../../tokenthera/pages/TheraSettlement";

function Component() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Redirect exact from={path} to={`${path}/transactions`} />
      <Route exact path={`${path}/transactions`}>
        <List />
      </Route>
      <Route exact path={`${path}/settlement`}>
        <TheraSettlement />
      </Route>
    </Switch>
  );
}

export default Component;
