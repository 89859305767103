import React, { useState } from "react";
import { useSelector } from "react-redux";
import DetailFacility from "../../components/DetailFacility";
import Schedule from "./contents/Schedule";
import TemplateFacilities from "../../components/TemplateFacilities";
import MembershipPackage from "./contents/MembershipPackage";

const info = {
  Information: [
    "name",
    "status",
    "check_in_start_minute",
    "location",
    "description",
    "created_date",
  ],
  "Other Facilities": ["open_at", "closed_at"],
  Rules: ["rules"],
};

function Component({ view, canDelete, canUpdate }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { role } = useSelector((state) => state.auth);
  const { selected } = useSelector((state) => state.facility);

  return (
    <>
      <TemplateFacilities
        loading={!selected?.id}
        thumbnail={selected?.thumbnail_url || "placeholder"}
        images={selected?.image_urls}
        title={selected?.name}
        phone={selected?.phone}
        pagetitle="Facility Information"
        labels={["Details", "Operational Hour", "Membership Package"]}
        contents={[
          <DetailFacility
            view={view}
            labels={info}
            editable={canUpdate}
            onDelete={() => {
              if (role !== "sa") {
                if (canDelete) {
                  return setConfirmDelete(true);
                } else {
                  return undefined;
                }
              }
              return setConfirmDelete(true);
            }}
          />,
          <Schedule view={view} schedule={selected?.operational_hour} />,
          <MembershipPackage view={view} />,
        ]}
      />
    </>
  );
}

export default Component;
