import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormatterPlus7, inputDateTimeFormatter24 } from "../../utils";
import { endpointBookingFacility } from "../../settings";
import { get } from "../slice";
import Table from "../../components/Table";
import { setDataLog } from "../slices/membership";
import Pill from "../../components/Pill";

const replaceDash = (str) => {
  return str.replace(/-/g, " ");
};

const columnsUnit = [
  { Header: "ID", accessor: "id" },
  {
    Header: "Resident",
    accessor: (row) => <p className="capitalize">{row.resident}</p>,
  },
  { Header: "Facility", accessor: (row) => row.facility },
  {
    Header: "Card Number",
    accessor: (row) => row.card_number,
  },
  {
    Header: "Tap In Time",
    accessor: (row) =>
      row.tap_in_time ? dateTimeFormatterPlus7(row.tap_in_time) : "-",
  },
  {
    Header: "Status",
    accessor: (row) =>
      row.status == "Check-in" ? (
        <Pill color={"success"}>
          <b className="capitalize">{replaceDash(row.status)}</b>
        </Pill>
      ) : (
        <Pill color={"warning"}>
          <b className="capitalize">{replaceDash(row.status)}</b>
        </Pill>
      ),
  },
];

function Component({ view, title = "", pagetitle, canAdd, canDelete }) {
  const { items, limit, total_pages, total_items, search } = useSelector(
    (state) => state.membership.log
  );

  let dispatch = useDispatch();

  return (
    <>
      <h2 className="PageTitle mt-3">Log Activity</h2>
      <div className="Container">
        <Table
          totalItems={total_items}
          columns={columnsUnit}
          data={items}
          loading={false}
          pageCount={total_pages}
          fetchData={useCallback(
            (pageIndex, pageSize, search) => {
              dispatch(
                get(
                  endpointBookingFacility +
                    "/admin/activity/logs?&page=" +
                    (pageIndex + 1) +
                    "&limit=" +
                    pageSize +
                    "&search=" +
                    search,
                  (res) => {
                    dispatch(setDataLog(res.data.data));
                  }
                )
              );
            },
            [dispatch, total_pages, total_items, limit, search]
          )}
          filters={[]}
        />
      </div>
    </>
  );
}

export default Component;
