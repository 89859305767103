import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FiSearch, FiPlus, FiCheck, FiDownload } from "react-icons/fi";
import moment from "moment";

import Button from "../../components/Button";
import Filter from "../../components/Filter";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import Task from "../../components/cells/Task";
import Pill from "../../components/Pill";

import Resident from "../../components/cells/Resident";
import Staff from "../../components/cells/Staff";

import { useSelector } from "react-redux";
import {
  toSentenceCase,
  isRangeThisMonth,
  isRangeToday,
  dateTimeFormatterCell,
} from "../../utils";
import {
  endpointAdmin,
  endpointManagement,
  taskStatusColor,
} from "../../settings";
import {
  getTask,
  resolveTask,
  reassignTask,
  setSelected,
  downloadTasks,
  setDateFilter,
  setTaskFilter,
} from "../slices/task";
import { get } from "../slice";

import Template from "./components/TemplateWithSelection";
import DateRangeFilter from "../../components/DateRangeFilter";

const columns = [
  {
    Header: "Title",
    accessor: (row) => (
      <Task
        id={row.id}
        data={row}
        items={[
          row.title,
          <small>{row.ref_code}</small>,
          row.unit_number ? (
            <small>
              From unit: <b>{row.unit_number}</b>
            </small>
          ) : (
            []
          ),
        ]}
      />
    ),
  },
  {
    Header: "Created On",
    accessor: (row) => dateTimeFormatterCell(row.created_on),
  },
  {
    Header: "Department",
    accessor: (row) =>
      row.task_type === "service" ? (
        <>
          {toSentenceCase(row.task_type) +
            (row.department_name ? "- " + row.department_name : "")}
        </>
      ) : (
        toSentenceCase(row.task_type) +
        (row.department_name ? "- " + row.department_name : "")
      ),
  },
  {
    Header: "Priority",
    accessor: (row) => {
      return (
        <Pill
          color={
            row.priority === "emergency"
              ? "danger"
              : row.priority === "high"
              ? "warning"
              : "success"
          }
        >
          {toSentenceCase(row.priority)}
        </Pill>
      );
    },
  },
  {
    Header: "Requester",
    accessor: (row) => (
      <Resident
        compact={true}
        id={row.requester_id}
        data={row.requester_details}
      />
    ),
  },
  {
    Header: "Assignee",
    accessor: (row) =>
      row.assignee_id ? (
        <Staff
          compact={true}
          id={row.assignee_id}
          data={row.assignee_details}
        />
      ) : (
        "-"
      ),
  },
  {
    Header: "Assigned on",
    accessor: (row) =>
      row.assigned_on ? dateTimeFormatterCell(row.assigned_on) : "-",
  },
  {
    Header: "Status",
    accessor: (row) => {
      console.log(row.status, "<<< row");

      return row.status ? (
        <Pill color={taskStatusColor[row.status]}>
          {toSentenceCase(row.status) +
            (row.status === "created" && row.priority === "emergency"
              ? ": Searching for Security"
              : "") +
            (row.status === "rejected" ? " by " + row.rejected_by : "")}
        </Pill>
      ) : (
        "-"
      );
    },
  },
  {
    Header: "Task Duration",
    accessor: (row) => (row.task_duration ? row.task_duration : "-"),
  },
];

const types = [
  { label: "Security", value: "security" },
  { label: "Service", value: "service" },
  { label: "Delivery", value: "delivery" },
];

const statuses = [
  { label: "Created", value: "created" },
  { label: "Canceled", value: "canceled" },
  { label: "Assigned", value: "assigned" },
  { label: "In Progress", value: "in_progress" },
  { label: "Reported", value: "reported" },
  { label: "Rejected", value: "rejected" },
  { label: "Approved", value: "approved" },
  { label: "Completed", value: "completed" },
  { label: "Timeout", value: "timeout" },
];

const prios = [
  { label: "Emergency", value: "emergency" },
  { label: "High", value: "high" },
  { label: "Normal", value: "normal" },
  { label: "Low", value: "low" },
];

function Component({ view, canAdd, canUpdate, canDelete }) {
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  let { url } = useRouteMatch();
  const { role } = useSelector((state) => state.auth);
  const { taskFilter, dateFilter } = useSelector((state) => state.task);

  const {
    building_id: building,
    building_name,
    priority,
    priority_label,
    status,
    status_label,
    type,
    type_label,
    unit,
    unit_label,
  } = taskFilter;
  const { createdStart, createdEnd } = dateFilter;

  const [selectedRow, setRow] = useState({});
  const [limit, setLimit] = useState(5);
  const [resolve, setResolve] = useState(false);
  const [assign, setAssign] = useState(false);
  const [staff, setStaff] = useState({});
  const [staffs, setStaffs] = useState([]);
  const [search, setSearch] = useState("");
  const [buildings, setBuildings] = useState("");

  const [units, setUnits] = useState([]);
  const [unitSearch, setUnitSearch] = useState("");
  const [multiRows, setMultiRows] = useState([]);
  const [confirmMultiResolve, setConfirmMultiResolve] = useState(false);

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildings(formatted);
          }
        )
      );
  }, [dispatch, search, limit]);

  useEffect(() => {
    if (search.length === 0) setLimit(5);
  }, [search]);

  useEffect(() => {
    let staffRole =
      selectedRow.task_type === "security"
        ? "security"
        : selectedRow.task_type === "service"
        ? "technician"
        : "courier";

    assign &&
      (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointManagement +
            "/admin/staff/list" +
            "?limit=5&page=1" +
            (selectedRow?.department_id
              ? "&department_id=" + selectedRow.department_id
              : "") +
            "&staff_role=" +
            staffRole +
            "&status=active" +
            (selectedRow.priority === "emergency"
              ? "&is_ongoing_emergency=true"
              : "") +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;

            let formatted = data.map((el) => ({
              label:
                el.departments.length > 0 ? (
                  <ListFilter data={el} />
                ) : (
                  el.firstname + " " + el.lastname
                ),
              value: el.id,
            }));

            setStaffs(formatted);
          }
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search, selectedRow]);

  useEffect(() => {
    building &&
      dispatch(
        get(
          endpointAdmin +
            "/building/unit/v2" +
            "?page=1" +
            "&building_id=" +
            building +
            "&search=" +
            unitSearch +
            "&sort_field=created_on&sort_type=DESC" +
            "&limit=" +
            limit,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            const formatted = res.data.data.items.map((el) => ({
              label:
                toSentenceCase(el.section_type) +
                " " +
                el.section_name +
                " " +
                el.number,
              value: el.id,
            }));

            if (data.length < totalItems && unitSearch.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setUnits(formatted);
          }
        )
      );
  }, [building, dispatch, limit, unitSearch]);

  return (
    <>
      <Modal
        isOpen={confirmMultiResolve}
        disableHeader={true}
        onClick={() => {
          const data = multiRows.map((el) => el);
          dispatch(resolveTask(data));
          setMultiRows([]);
          setConfirmMultiResolve(false);
        }}
        toggle={() => {
          setConfirmMultiResolve(false);
          setMultiRows([]);
        }}
        okLabel={"Resolve All"}
        cancelLabel={"Cancel"}
      >
        Are you sure you want to resolve these tasks?
        <p style={{ paddingTop: "10px" }}>
          <ul>
            {multiRows.map((el) => (
              <li>
                {el.ref_code} - {el.title}
              </li>
            ))}
          </ul>
        </p>
      </Modal>
      <Modal
        isOpen={resolve}
        toggle={() => setResolve(false)}
        disableHeader
        okLabel="Yes"
        onClick={() => {
          setResolve(false);
          dispatch(resolveTask([selectedRow]));
        }}
        cancelLabel="No"
        onClickSecondary={() => {
          setResolve(false);
        }}
      >
        Are you sure you want to resolve this task?
      </Modal>
      <Modal
        isOpen={assign}
        toggle={() => setAssign(false)}
        disableHeader
        disableFooter={staffs.length === 0}
        okLabel="Yes"
        onClick={() => {
          setStaff({});
          setAssign(false);
          dispatch(
            reassignTask({
              task_id: selectedRow.id,
              assignee_id: staff.value,
            })
          );
        }}
        cancelLabel="No"
        onClickSecondary={() => {
          setStaff({});
          setAssign(false);
        }}
      >
        Choose assignee:
        {staffs.length !== 0 && !staff.value && (
          <Input
            label="Search"
            icon={<FiSearch />}
            compact
            inputValue={search}
            setInputValue={setSearch}
          />
        )}
        <Filter
          data={staff.value ? [staff] : staffs}
          onClick={(el) => setStaff(el)}
        />
        {staffs.length === 0 && (
          <p
            style={{
              fontStyle: "italic",
            }}
          >
            No elligible staff found.
          </p>
        )}
      </Modal>
      <Template
        filterExpanded={history.location.state}
        view={view}
        pagetitle="Task List"
        columns={columns}
        slice="task"
        getAction={getTask}
        selectAction={(selectedRows) =>
          setMultiRows(selectedRows.filter((el) => el.status !== "completed"))
        }
        filterVars={[
          type,
          priority,
          status,
          building,
          unit,
          createdStart,
          createdEnd,
          // ...(status === "completed" ? [resolvedStart, resolvedEnd] : []),
        ]}
        filters={[
          {
            hidex: isRangeThisMonth(createdStart, createdEnd),
            label: "Created Date: ",
            delete: () => {
              dispatch(
                setDateFilter({
                  createdStart: moment().startOf("month").format("yyyy-MM-DD"),
                  createdEnd: moment().endOf("month").format("yyyy-MM-DD"),
                })
              );
            },
            value:
              moment(createdStart).format("DD-MM-yyyy") +
              " - " +
              moment(createdEnd).format("DD-MM-yyyy"),
            component: (toggleModal) => (
              <DateRangeFilter
                startDate={createdStart}
                endDate={createdEnd}
                onApply={(start, end) => {
                  dispatch(
                    setDateFilter({
                      createdStart: start,
                      createdEnd: end,
                    })
                  );
                  toggleModal();
                }}
              />
            ),
          },
          {
            hidex: building === "",
            label: "Building: ",
            value: building ? building_name : "All",
            delete: () => {
              dispatch(
                setTaskFilter({
                  ...taskFilter,
                  building_id: "",
                  building_name: "",
                })
              );
            },
            component: (toggleModal) => (
              <>
                <Input
                  label="Search Building"
                  compact
                  icon={<FiSearch />}
                  inputValue={search}
                  setInputValue={setSearch}
                />
                <Filter
                  data={buildings}
                  onClick={(el) => {
                    if (!el.value) {
                      setLimit(limit + el.restTotal);
                      return;
                    }
                    dispatch(
                      setTaskFilter({
                        ...taskFilter,
                        building_id: el.value,
                        building_name: el.label,
                      })
                    );
                    setLimit(5);
                    toggleModal(false);
                  }}
                  onClickAll={() => {
                    dispatch(
                      setTaskFilter({
                        ...taskFilter,
                        building_id: "",
                        building_name: "",
                      })
                    );
                    setLimit(5);
                    toggleModal(false);
                  }}
                />
              </>
            ),
          },
          ...(building
            ? [
                {
                  hidex: unit === "",
                  label: "Unit: ",
                  value: unit ? unit_label : "All",
                  delete: () => {
                    dispatch(
                      setTaskFilter({ ...taskFilter, unit: "", unit_label: "" })
                    );
                  },
                  component: (toggleModal) => (
                    <>
                      <Input
                        label="Search Unit"
                        compact
                        icon={<FiSearch />}
                        inputValue={unitSearch}
                        setInputValue={setUnitSearch}
                      />
                      <Filter
                        data={units}
                        onClick={(el) => {
                          if (!el.value) {
                            setLimit(limit + el.restTotal);
                            return;
                          }
                          dispatch(
                            setTaskFilter({
                              ...taskFilter,
                              unit: el.value,
                              unit_label: el.label,
                            })
                          );
                          setLimit(5);
                          toggleModal(false);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setTaskFilter({
                              ...taskFilter,
                              unit: "",
                              unit_label: "",
                            })
                          );
                          setLimit(5);
                          toggleModal(false);
                        }}
                      />
                      {/* {unitSearch ? 'Showing at most 10 matching units' :
                                'Showing 10 most recent units'} */}
                    </>
                  ),
                },
              ]
            : []),
          {
            hidex: type === "",
            label: "Type: ",
            value: type ? type_label : "All",
            delete: () => {
              dispatch(
                setTaskFilter({ ...taskFilter, type: "", type_label: "" })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={types}
                onClick={(el) => {
                  dispatch(
                    setTaskFilter({
                      ...taskFilter,
                      type: el.value,
                      type_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
                onClickAll={() => {
                  dispatch(
                    setTaskFilter({ ...taskFilter, type: "", type_label: "" })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: priority === "",
            label: "Priority: ",
            value: priority ? priority_label : "All",
            delete: () => {
              dispatch(
                setTaskFilter({
                  ...taskFilter,
                  priority: "",
                  priority_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={prios}
                onClick={(el) => {
                  dispatch(
                    setTaskFilter({
                      ...taskFilter,
                      priority: el.value,
                      priority_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
                onClickAll={() => {
                  dispatch(
                    setTaskFilter({
                      ...taskFilter,
                      priority: "",
                      priority_label: "",
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
          {
            hidex: status === "",
            label: "Status: ",
            value: status ? status_label : "All",
            delete: () => {
              dispatch(
                setTaskFilter({
                  ...taskFilter,
                  status: "",
                  status_label: "",
                })
              );
            },
            component: (toggleModal) => (
              <Filter
                data={statuses}
                onClick={(el) => {
                  dispatch(
                    setTaskFilter({
                      ...taskFilter,
                      status: el.value,
                      status_label: el.label,
                    })
                  );
                  toggleModal(false);
                }}
                onClickAll={() => {
                  dispatch(
                    setTaskFilter({
                      ...taskFilter,
                      status: "",
                      status_label: "",
                    })
                  );
                  toggleModal(false);
                }}
              />
            ),
          },
        ]}
        actionDownloads={
          view
            ? null
            : [
                <Button
                  fontWeight={500}
                  color="Download"
                  label="Download Tasks.csv"
                  icon={<FiDownload />}
                  onClick={() =>
                    dispatch(
                      downloadTasks(
                        1,
                        10000,
                        search,
                        type,
                        priority,
                        status,
                        building,
                        unit,
                        createdStart,
                        createdEnd,
                        // resolvedStart,
                        // resolvedEnd,
                        "true"
                      )
                    )
                  }
                />,
              ]
        }
        renderActions={
          view
            ? null
            : (selectedRowIds, page) => {
                return [
                  <>
                    {Object.keys(selectedRowIds).length > 0 &&
                      (role === "bm" ? canUpdate : true) && (
                        <Button
                          icon={<FiCheck />}
                          color="success"
                          onClick={() => {
                            setConfirmMultiResolve(true);
                          }}
                          label="Resolve All"
                        />
                      )}
                  </>,
                  role === "bm" && (role === "bm" ? canAdd : false) && (
                    <Button
                      key="Add Task"
                      label="Add Task"
                      icon={<FiPlus />}
                      onClick={() => {
                        dispatch(setSelected({}));
                        history.push(url + "/add");
                      }}
                    />
                  ),
                ];
              }
        }
        onClickResolve={
          view
            ? null
            : (role === "bm" ? canUpdate : true)
            ? (row) => {
                setRow(row);
                setResolve(true);
              }
            : undefined
        }
        onClickReassign={
          view
            ? null
            : (row) => {
                setRow(row);
                setAssign(true);
              }
        }
        onClickChat={
          view
            ? null
            : role === "sa"
            ? null
            : (row) => {
                dispatch(setSelected(row));
                history.push("chat/" + row.ref_code);
              }
        }
      />
    </>
  );
}

export default Component;

const ListFilter = ({ data }) => {
  const [modalHover, setModalHover] = useState(false);
  return (
    <div className="modal-hover">
      {data.firstname + " " + data.lastname}
      <button
        className="ml-2"
        onMouseEnter={() => setModalHover(true)}
        onMouseLeave={() => setModalHover(false)}
      >
        See Departments
      </button>
      <div
        className={"list-modal-hover text-left" + (modalHover ? " on" : "")}
        onMouseEnter={() => setModalHover(true)}
        onMouseLeave={() => setModalHover(false)}
      >
        {data.departments.map((item, i) => (
          <div className="p-3">
            {i +
              1 +
              ". " +
              item.department_name +
              " (" +
              item.department_type +
              ")"}
          </div>
        ))}
      </div>
    </div>
  );
};

// ...(status === "completed"
//   ? [
//       // {
//       //   hidex: isRangeToday(resolvedStart, resolvedEnd),
//       //   label: "Resolved Date: ",
//       //   delete: () => {
//       //     setResolvedStart(today);
//       //     setResolvedEnd(today);
//       //   },
//       //   value: isRangeToday(resolvedStart, resolvedEnd)
//       //     ? "Today"
//       //     : moment(resolvedStart).format("DD-MM-yyyy") +
//       //       " - " +
//       //       moment(resolvedEnd).format("DD-MM-yyyy"),
//       //   component: (toggleModal) => (
//       //     <DateRangeFilter
//       //       startDate={resolvedStart}
//       //       endDate={resolvedEnd}
//       //       onApply={(start, end) => {
//       //         setResolvedStart(start);
//       //         setResolvedEnd(end);
//       //         toggleModal();
//       //       }}
//       //     />
//       //   ),
//       // },
//     ]
//   : []),

// const [createdStart, setCreatedStart] = useState(
//   dateFilter.createdStart ? dateFilter.createdStart : defaultStartDate
// );
// const [createdEnd, setCreatedEnd] = useState(
//   dateFilter.createdEnd ? dateFilter.createdEnd : defaultEndDate
// );
// const [resolvedStart, setResolvedStart] = useState(defaultStartDate);
// const [resolvedEnd, setResolvedEnd] = useState(defaultEndDate);

// useEffect(() => {
//   history.location.state &&
//     history.location.state.type &&
//     setType(history.location.state.type);
//   history.location.state &&
//     history.location.state.status &&
//     setStatus(history.location.state.status);

//   history.location.state &&
//     history.location.state.typeLabel &&
//     setTypeLabel(history.location.state.typeLabel);
//   history.location.state &&
//     history.location.state.statusLabel &&
//     setStatusLabel(history.location.state.statusLabel);
// }, [history.location.state]);
