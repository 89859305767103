import React, { useState } from "react";
import { motion } from "framer-motion";

const ToggleSwitch = ({ onFunction, offFunction, conditional, disabled }) => {
  const [isOn, setIsOn] = useState(conditional || false);

  const handleToggle = () => {
    if (isOn) {
      setIsOn(false);
      offFunction();
    } else {
      setIsOn(true);
      onFunction();
    }
  };

  return (
    <div className="flex items-center">
      {disabled ? (
        <div
          className={`relative w-12 h-7 rounded-full cursor-not-allowed ${
            isOn ? "bg-green-500" : "bg-gray-300"
          }`}
        >
          <motion.div
            className="absolute top-[0.2rem] left-1 size-[1.35rem] bg-white rounded-full shadow-md"
            animate={{ x: isOn ? 14 : 0 }}
            transition={{ type: "spring", stiffness: 700, damping: 50 }}
          />
        </div>
      ) : (
        <div
          className={`relative w-12 h-7 rounded-full cursor-pointer ${
            isOn ? "bg-green-500" : "bg-gray-300"
          }`}
          onClick={handleToggle}
        >
          <motion.div
            className="absolute top-[0.2rem] left-1 size-[1.35rem] bg-white rounded-full shadow-md"
            animate={{ x: isOn ? 14 : 0 }}
            transition={{ type: "spring", stiffness: 700, damping: 50 }}
          />
        </div>
      )}
    </div>
  );
};

export default ToggleSwitch;
