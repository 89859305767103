import { FiHelpCircle } from "react-icons/fi";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { Button } from "reactstrap";

function MyButton({ icon, onClick, color, disabled, className }) {
  const [modalHover, setModalHover] = useState(false);
  return (
    <>
      <div className="modal-hover">
        <Tooltip id={"modal-wizard"} place="top" />
        <div
          onClick={onClick}
          disabled={disabled}
          className="p-2 px-3 border-[1px] rounded-md flex items-center justify-center gap-x-2 cursor-pointer hover:bg-red-600 hover:text-white transition-all hover:border-red-600"
        >
          <FiHelpCircle className="mt-0.5" />
          <h1 className="text-[12px]">How to Use Guide</h1>
        </div>
        {/* <Button
          // className="bg-white"
          data-tooltip-id="modal-wizard"
          data-tooltip-content={"Pelajari Selengkapnya"}
          disabled={disabled}
          onClick={onClick}
          // className={
          //   "ButtonWizard " +
          //   (disabled ? "inactive" : color ? color : "") +
          //   (className ? " " + className : "")
          // }
          // style={{
          //   paddingRight: 10,
          //   paddingLeft: 10,
          //   marginRight: 4,
          //   marginLeft: 4,
          //   marginBottom: 8,
          //   backgroundColor: color ? "" : "#244091",
          //   borderRadius: 6,
          // }}
          // onMouseEnter={() => setModalHover(true)}
          // onMouseLeave={() => setModalHover(false)}
        >
          {icon && (
            <span
              style={{
                marginBottom: 2,
              }}
            >
              {icon}
            </span>
          )}
        </Button> */}
      </div>
    </>
  );
}

export default MyButton;
