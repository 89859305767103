import { BiCheckboxSquare } from "react-icons/bi";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import Button from "../../../components/Button";
import { FiEdit } from "react-icons/fi";
import { GiTowel } from "react-icons/gi";
import { AiOutlineWifi } from "react-icons/ai";
import { FaToilet } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { dateTimeFormatter, dateTimeFormatterPlus7 } from "../../../utils";
import { toFacilityDetail } from "../../slices/facility";
import Pill from "../../../components/Pill";

function Component({
  view = false,
  data,
  labels,
  editable,
  editPath = "edit",
  renderButtons = () => {},
}) {
  const { selected } = useSelector((state) => state.facility);
  let history = useHistory();
  let dispatch = useDispatch();
  let { url } = useRouteMatch();

  const replaceUnderscore = (str) => {
    return str.replace(/_/g, " ");
  };

  return (
    <div className="flex justify-between px-3 pt-2">
      <div className="flex w-full flex-col gap-y-10">
        <div className="flex flex-col gap-y-2">
          <h1 className="border-b-[1px] pb-2 font-bold w-[200px] mb-2">
            Information
          </h1>
          {labels.Information.map((el, index) => (
            <div
              key={index}
              className="grid grid-cols-2 w-[70%] space-y-2 items-center text-left"
            >
              <h1 className="capitalize">{replaceUnderscore(el)}</h1>
              <h1 className="font-bold capitalize">
                {el == "created_date" ? (
                  dateTimeFormatterPlus7(selected[el])
                ) : el == "status" ? (
                  selected[el] == "open" ? (
                    <Pill color={"success"}>
                      <b className="capitalize">{selected[el]}</b>
                    </Pill>
                  ) : (
                    <Pill color={"secondary"}>
                      <b className="capitalize">{selected[el]}</b>
                    </Pill>
                  )
                ) : (
                  selected[el]
                )}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-y-2">
          <h1 className="border-b-[1px] pb-2 font-bold w-[200px] mb-2">
            Rules
          </h1>
          <div className="flex flex-col gap-y-3">
            {selected.rules.map((el, index) => (
              <div key={index} className="flex gap-x-2 w-[70%] items-center">
                <BiCheckboxSquare />
                <h1>{el}</h1>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <h1 className="border-b-[1px] pb-2 font-bold w-[200px] mb-2.5">
            Other Facilities
          </h1>
          <div className="flex gap-x-4">
            <div className="flex flex-col gap-y-2 items-center justify-center bg-red-50 rounded-md px-3 py-3 border-red-600 border-[1px]">
              <FaToilet className="size-16 bg-red-600 p-3 text-white rounded-lg" />
              <h1 className="font-bold text-[11px]">
                {selected?.other_facilities[0]?.is_check
                  ? "Available"
                  : "Unavailable"}
              </h1>
            </div>
            <div className="flex flex-col gap-y-2 items-center justify-center bg-red-50 rounded-md px-3 py-3 border-red-600 border-[1px]">
              <GiTowel className="size-16 bg-red-600 p-2 text-white rounded-lg" />
              <h1 className="font-bold text-[11px]">
                {selected?.other_facilities[1]?.is_check
                  ? "Available"
                  : "Unavailable"}
              </h1>
            </div>
            <div className="flex flex-col gap-y-2 items-center justify-center bg-red-50 rounded-md px-3 py-3 border-red-600 border-[1px]">
              <AiOutlineWifi className="size-16 bg-red-600 p-2 text-white rounded-lg" />
              <h1 className="font-bold text-[11px]">
                {selected?.other_facilities[2]?.is_check
                  ? "Available"
                  : "Unavailable"}
              </h1>
            </div>
          </div>
        </div>
      </div>
      {view && (
        <div className="col-auto d-flex flex-column">
          {editable && (
            <Button
              icon={<FiEdit />}
              label="Edit"
              onClick={() =>
                dispatch(toFacilityDetail(selected.id, history, true))
              }
            />
          )}
          {renderButtons()}
        </div>
      )}
    </div>
  );
}

export default Component;
