import React, { useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import {
  createMembershipPackage,
  deleteMembershipPackage,
} from "../../../../slices/facility";
import { toMoneyRP } from "../../../../../utils";
import Table from "../../../../../components/Table";
import Button from "../../../../../components/Button";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Input";
import MyButton from "../../../../../components/Button";

const MembershipPackage = ({ view = true, canDelete = true }) => {
  const { selected_package } = useSelector((state) => state.facility);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalAddMember, setModalAddMember] = useState(false);
  const [modalDeleteMember, setModalDeleteMember] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const columns = [
    { Header: "Package Name", accessor: "name" },
    { Header: "Member Period", accessor: "member_period" },
    { Header: "Price", accessor: (row) => <p>Rp {toMoneyRP(row.price)}</p> },
  ];

  return (
    <>
      <div className="flex justify-between items-center w-full px-2 -mb-2">
        <h1 className="text-[16px] font-bold">Package Membership</h1>
        <Button
          key="Add Package"
          label="Add Package"
          icon={<FiPlus />}
          onClick={() => {
            setModalAddMember(true);
          }}
        />
      </div>
      <Table
        noSearch={true}
        noContainer={true}
        columns={columns}
        data={selected_package ? selected_package : []}
        pagination={false}
        onClickDelete={(row) => {
          setModalDeleteMember(true);
          setSelectedId(row.id);
        }}
      />

      {/* Modal Add Membership Package */}
      <Modal
        isOpen={modalAddMember}
        toggle={() => setModalAddMember(false)}
        title={"Add Membership Package"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ name: "", member_period: "", price: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              createMembershipPackage(values, setModalAddMember, id, history)
            );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2 pb-4">
                  <Input
                    label="Name"
                    edit={"name"}
                    setInputValue={(e) => setFieldValue("name", e)}
                    star={true}
                    placeholder={"e.g. Platinum"}
                    className={"outline-none focus:outline-red-600"}
                  />
                  <div className="relative w-full">
                    <Input
                      className={"pl-14 outline-none focus:outline-red-600"}
                      label="Price"
                      edit={"price"}
                      star={true}
                      setInputValue={(e) => setFieldValue("price", e)}
                    />
                    <h1 className="absolute font-bold left-5 top-[4.8rem]">
                      Rp
                    </h1>
                  </div>
                  <div className="relative w-full">
                    <Input
                      className={"pr-[7rem] outline-none focus:outline-red-600"}
                      star={true}
                      label="Membership Period (day)"
                      edit={"member_period"}
                      setInputValue={(e) =>
                        setFieldValue("member_period", parseInt(e))
                      }
                      placeholder={"e.g. 30"}
                    />
                    <h1 className="absolute right-7 top-[4.8rem] border-l-2 pl-4">
                      days
                    </h1>
                  </div>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Back"}
                    btnType={"button"}
                    onClick={() => setModalAddMember(false)}
                    color={"secondary"}
                  />
                  <MyButton label={"Submit"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Delete Membership Package */}
      <Modal
        isOpen={modalDeleteMember}
        toggle={() => setModalDeleteMember(false)}
        title={"Delete Package"}
        disableFooter={true}
      >
        <div className="px-2">
          <p className="py-12">
            Are you sure you want to delete membership package? <br /> All
            stored data will be automatically deleted by the system
          </p>
        </div>

        <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
          <MyButton
            label={"Cancel"}
            btnType={"button"}
            onClick={() => setModalDeleteMember(false)}
            color={"secondary"}
          />
          <MyButton
            label={"Delete"}
            btnType={"submit"}
            onClick={() =>
              dispatch(
                deleteMembershipPackage(id, setModalDeleteMember, selectedId)
              )
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default MembershipPackage;
