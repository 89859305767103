import React, { useEffect, useState } from "react";
import { BiTimeFive } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../features/form/inputBooking";
import MyButton from "../../../../../components/Button";
import { Form, Formik } from "formik";
import {
  checkInConfirmation,
  checkOutConfirmation,
  setModalMembership,
  setModalReset,
  updateDeleteMembership,
  updateMembership,
} from "../../../../slices/membership";
import {
  facilitiesOptions,
  getDaysRemainingMembership,
} from "../../utils/statics";
import { toMoneyRP } from "../../../../../utils";
import ModalSuccessMembership from "./ModalSuccessMembership";
import { endpointBookingFacility } from "../../../../../settings";
import { toast } from "sonner";
import { get } from "../../../../slice";

const ModalConfirmation = ({ setCardValid, setCardInvalid }) => {
  const { selected, member_selected, selected_activated } = useSelector(
    (state) => state.membership
  );
  const {
    checkinMembership,
    checkoutMembership,
    setAsPaid,
    setInactiveMembership,
    deleteMembership,
    reActivateMembership,
    extendMembership,
    activeMembership,
    changeCardNumber,
  } = useSelector((state) => state.membership.modal);
  const dispatch = useDispatch();
  const [confirmReActivate, setConfirmReActivate] = useState(false);
  const [reActivePayload, setReActivePayload] = useState({
    package_id: "",
    card_number: "",
  });
  const [packageOptions, setPackageOptions] = useState([]);

  //! saat payload berhasil di modal confirmation harusnya form kembali empty

  function getDayLabel(number) {
    return number === 1 ? "day" : "days";
  }

  // Package Options for Re Activated Member
  useEffect(() => {
    dispatch(
      get(
        endpointBookingFacility +
          "/admin/facilities/" +
          selected_activated?.facility?.id +
          "/packages",
        (res) => {
          let packages = res.data.data;

          if (packages.length > 0) {
            let formatted = packages.map((el) => ({
              label: `${el.name} | ${el.member_period} ${getDayLabel(
                el.member_period
              )} | Rp ${toMoneyRP(el.price)}`,
              value: el.id,
            }));

            setPackageOptions(formatted);
          } else {
            toast.error(`This facility doesn't have packages`);
          }
        }
      )
    );
  }, [dispatch, selected_activated]);

  return (
    <>
      {/* Modal Check In */}
      <Modal
        isOpen={checkinMembership || checkoutMembership}
        toggle={() => dispatch(setModalReset())}
        title={
          checkinMembership ? "Check In Confirmation" : "Check Out Confirmation"
        }
        disableFooter={true}
      >
        <Formik
          initialValues={{ card_number: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            checkinMembership
              ? dispatch(
                  checkInConfirmation(
                    values,
                    member_selected?.id,
                    setCardValid,
                    setCardInvalid
                  )
                )
              : dispatch(
                  checkOutConfirmation(
                    values,
                    member_selected?.id,
                    setCardValid,
                    setCardInvalid
                  )
                );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2 pb-4 pt-3">
                  <h1 className="text-left mb-2 mt-2">
                    Access Card Number<b className="text-red-600">*</b>
                  </h1>
                  <input
                    autoComplete="off"
                    className="w-full outline-none focus:outline-red-600 cursor-pointer"
                    placeholder={"Tap the card on the card reader"}
                    onKeyDown={(e) =>
                      e.key === "Enter" && (e.preventDefault(), handleSubmit())
                    }
                    onClick={(e) => e.target.select()}
                    onChange={(e) =>
                      setFieldValue("card_number", e.target.value)
                    }
                  />
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => {
                      dispatch(setModalReset());
                    }}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Set as Paid Member */}
      <Modal
        isOpen={setAsPaid}
        toggle={() => dispatch(setModalReset())}
        title={"Set as Paid Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ id: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              updateMembership(
                selected.id,
                "status",
                { status: "paid" },
                "set as paid"
              )
            );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2">
                  <p className="py-10">
                    Are you sure you want to set selected member as Paid?
                  </p>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => dispatch(setModalReset())}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Set Inactive Membership */}
      <Modal
        isOpen={setInactiveMembership}
        toggle={() => dispatch(setModalReset())}
        title={"Set to Inactive Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ name: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              updateMembership(
                selected?.id,
                "status",
                { status: "inactive" },
                "inactive"
              )
            );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2">
                  <p className="py-10">
                    Are you sure you want to Set Inactive member?
                  </p>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => dispatch(setModalReset())}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Delete Membership */}
      <Modal
        isOpen={deleteMembership}
        toggle={() => dispatch(setModalReset())}
        title={"Delete Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ package_id: "", card_number: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            let payload = {
              package_id: selected?.package_id,
              card_number: selected?.card_number,
            };

            dispatch(updateDeleteMembership(payload, selected?.id));
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2">
                  <p className="py-10">
                    Are you sure you want to delete member? <br />
                    All stored data will be automatically deleted by the system
                  </p>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => dispatch(setModalReset())}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Re-Activated Membership */}
      <Modal
        isOpen={reActivateMembership}
        toggle={() => {
          dispatch(setModalReset());
          setReActivePayload({ package_id: "", card_number: "" });
        }}
        title={"Re-Activated Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={reActivePayload}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            setReActivePayload(values);
            dispatch(setModalReset());
            setConfirmReActivate(true);
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;
            return (
              <Form>
                <div className="px-2.5 flex flex-col gap-y-2 items-start py-8">
                  <h1 className="mb-3 font-bold">Previous Membership</h1>
                  <div className="bg-[#FAFAFC] w-full flex flex-col gap-y-2 p-[1.5rem] rounded-xl items-start">
                    <div className="flex flex-col gap-y-2 items-start border-b-[1.4px] border-gray-300 pb-4 w-full border-dashed">
                      <h1 className="text-[18px] font-bold">
                        {selected_activated?.package?.name}
                      </h1>
                      <h1 className="text-gray-400 text-[13px]">
                        {selected_activated?.facility?.name}
                      </h1>
                    </div>

                    <div className="flex flex-col gap-y-3 w-full pt-3">
                      <div className="flex justify-between">
                        <h1 className="font-normal">Membership Period</h1>
                        <h1 className="font-bold">
                          {selected_activated?.package?.member_period}{" "}
                          {getDayLabel(
                            selected_activated?.package?.member_period
                          )}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Price</h1>
                        <h1 className="font-bold">
                          Rp{toMoneyRP(selected_activated?.package?.price)}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-200 h-[1px] w-full my-4"></div>

                  <h1 className="font-bold mb-3">Extend Information</h1>
                  <div className="w-full flex flex-col gap-y-4 rounded-lg items-start">
                    <Input
                      {...props}
                      autoComplete="off"
                      label="Package Member"
                      name={"package_id"}
                      placeholder={"Select Extend Package"}
                      options={packageOptions}
                      star={true}
                      setInputValue={(e) =>
                        setFieldValue("package_id", e.value)
                      }
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                    />
                    <Input
                      {...props}
                      autoComplete="off"
                      label="Card Number"
                      name={"card_number"}
                      onClick={(e) => e.target.select()}
                      placeholder={"Tap the card on the card reader"}
                      setInputValue={(e) => setFieldValue("card_number", e)}
                      star={true}
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                      onKeyPress={(e) =>
                        e.key === "Enter" && e.preventDefault()
                      }
                    />
                  </div>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => {
                      dispatch(setModalReset());
                      setReActivePayload({ package_id: "", card_number: "" });
                    }}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Re-Activate Membership Confirmation */}
      <Modal
        isOpen={confirmReActivate}
        toggle={() => {
          setConfirmReActivate(false);
          dispatch(setModalMembership({ reActivateMembership: true }));
        }}
        title={"Re-Activate Membership"}
        disableFooter={true}
      >
        <Formik
          initialValues={reActivePayload}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            setConfirmReActivate(false);
            dispatch(
              updateMembership(
                selected_activated?.id,
                "extend",
                reActivePayload,
                "re-activation"
              )
            );
            setReActivePayload({ package_id: "", card_number: "" });
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2">
                  <p className="py-10">
                    Are you sure you want to Re-Activate Membership?
                  </p>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => {
                      setConfirmReActivate(false);
                      dispatch(
                        setModalMembership({ reActivateMembership: true })
                      );
                    }}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Active Member */}
      <Modal
        isOpen={activeMembership}
        toggle={() => dispatch(setModalReset())}
        title={"Active Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ name: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              updateMembership(
                selected?.id,
                "status",
                { status: "activate" },
                "activation"
              )
            );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            console.log(selected_activated, "<<< member");

            return (
              <Form>
                <div className="px-2.5 flex flex-col gap-y-4 font-bold items-start py-8">
                  <h1 className="mb-1 text-left font-light leading-7 w-full">
                    Are you sure you want to Activate selected <br />
                    membership package?
                  </h1>
                  <div className="bg-[#FAFAFC] w-full flex flex-col gap-y-2 p-[1.5rem] rounded-xl items-start">
                    <h1 className="text-left">User Information</h1>

                    <div className="flex flex-col gap-y-4 w-full pt-2">
                      <div className="flex justify-between">
                        <h1 className="font-normal">Username</h1>
                        <h1>{selected_activated?.resident?.name}</h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Email</h1>
                        <h1>{selected_activated?.resident?.email}</h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Phone Number</h1>
                        <h1>+{selected_activated?.resident?.phone}</h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-[#FAFAFC] w-full flex flex-col gap-y-2 p-[1.5rem] rounded-xl items-start">
                    <h1 className="text-left">Membership Information</h1>

                    <div className="flex flex-col gap-y-4 w-full pt-2">
                      <div className="flex justify-between">
                        <h1 className="font-normal">Facility</h1>
                        <h1>{selected_activated?.facility?.name}</h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Package Member</h1>
                        <h1>{selected_activated?.package?.name}</h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Membership Period</h1>
                        <h1>
                          {selected_activated?.package?.member_period}{" "}
                          {getDayLabel(
                            selected_activated?.package?.member_period
                          )}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Membership Price</h1>
                        <h1>
                          Rp
                          {toMoneyRP(
                            parseInt(selected_activated?.package?.price)
                          )}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Card Number</h1>
                        <h1>{selected_activated?.access_card?.card_number}</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => dispatch(setModalReset())}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Extend Membership */}
      <Modal
        isOpen={extendMembership}
        toggle={() => {
          dispatch(setModalReset());
          setReActivePayload({ package_id: "", card_number: "" });
        }}
        title={"Extend Member"}
        disableFooter={true}
      >
        <Formik
          initialValues={reActivePayload}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              updateMembership(
                selected_activated?.id,
                "extend",
                values,
                "extend package"
              )
            );
            setReActivePayload({ package_id: "", card_number: "" });
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2.5 flex flex-col gap-y-2 items-start py-8">
                  <h1 className="mb-3 font-bold">Previous Membership</h1>
                  <div className="bg-[#FAFAFC] w-full flex flex-col gap-y-2 p-[1.5rem] rounded-xl items-start">
                    <div className="flex flex-col gap-y-2 items-start border-b-[1.4px] border-gray-300 pb-4 w-full border-dashed">
                      <h1 className="text-[18px] font-bold">
                        {selected_activated?.package?.name}
                      </h1>
                      <h1 className="text-gray-400 text-[13px]">
                        {selected_activated?.facility?.name}
                      </h1>
                    </div>

                    <div className="flex flex-col gap-y-3 w-full pt-3">
                      <div className="flex justify-between">
                        <h1 className="font-normal">Membership Period</h1>
                        <h1 className="font-bold">
                          {selected_activated?.package?.member_period}{" "}
                          {getDayLabel(
                            selected_activated?.package?.member_period
                          )}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Price</h1>
                        <h1 className="font-bold">
                          Rp{toMoneyRP(selected_activated?.package?.price)}
                        </h1>
                      </div>
                    </div>

                    <div className="bg-[#FDE9EC] rounded-lg w-full h-12 mt-2 text-red-600 flex items-center justify-center gap-x-1 text-[12px]">
                      <BiTimeFive className="size-5" />
                      <h1 className="font-light">
                        Remaining Membership Period:{" "}
                        <b className="font-bold">
                          {getDaysRemainingMembership(
                            selected_activated?.expired_date
                          )}
                        </b>
                      </h1>
                    </div>
                  </div>

                  <div className="bg-gray-200 h-[1px] w-full my-4"></div>

                  <h1 className="mb-3 font-bold">Extend Information</h1>
                  <div className="w-full flex flex-col gap-y-4 rounded-lg items-start">
                    <Input
                      {...props}
                      autoComplete="off"
                      label="Package Member"
                      name={"package_id"}
                      placeholder={"Select Extend Package"}
                      options={packageOptions}
                      star={true}
                      setInputValue={(e) =>
                        setFieldValue("package_id", e.value)
                      }
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                    />
                    <Input
                      {...props}
                      star={true}
                      label="Card Number"
                      name={"card_number"}
                      autoComplete="off"
                      onClick={(e) => e.target.select()}
                      placeholder={"Tap the card on the card reader"}
                      setInputValue={(e) => setFieldValue("card_number", e)}
                      onKeyPress={(e) =>
                        e.key === "Enter" && e.preventDefault()
                      }
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                    />
                  </div>

                  <div className="bg-[#F5F8FF] rounded-lg w-full mt-3 text-[#244091] flex items-start justify-center gap-x-2 text-[12px] px-3 py-3">
                    <BiTimeFive className="size-6" />
                    <h1 className="font-light text-left">
                      Sisa waktu membership sebelumnya otomatis digabungkan
                      dengan waktu membership baru.
                    </h1>
                  </div>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => {
                      dispatch(setModalReset());
                      setReActivePayload({ package_id: "", card_number: "" });
                    }}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Change Card Number */}
      <Modal
        isOpen={changeCardNumber}
        toggle={() => dispatch(setModalReset())}
        title={"Change Card Number"}
        disableFooter={true}
      >
        <Formik
          initialValues={{ card_number: "" }}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            dispatch(
              updateMembership(
                selected_activated?.id,
                "change_card",
                values,
                "change card number"
              )
            );
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2.5 flex flex-col gap-y-4 items-start py-8">
                  <h1 className="mb-2 font-bold">Active Membership</h1>
                  <div className="bg-[#FAFAFC] w-full flex flex-col gap-y-2 p-[1.5rem] rounded-xl items-start">
                    <div className="flex flex-col gap-y-2 items-start border-b-[1.4px] border-gray-300 pb-4 w-full border-dashed">
                      <h1 className="text-[18px] font-bold">
                        {selected_activated?.package?.name}
                      </h1>
                      <h1 className="text-gray-400 text-[13px]">
                        {selected_activated?.facility?.name}
                      </h1>
                    </div>

                    <div className="flex flex-col gap-y-3 w-full pt-3">
                      <div className="flex justify-between">
                        <h1 className="font-normal">Membership Period</h1>
                        <h1 className="font-bold">
                          {selected_activated?.package?.member_period}{" "}
                          {getDayLabel(
                            selected_activated?.package?.member_period
                          )}
                        </h1>
                      </div>
                      <div className="flex justify-between">
                        <h1 className="font-normal">Price</h1>
                        <h1 className="font-bold">
                          Rp{toMoneyRP(selected_activated?.package?.price)}
                        </h1>
                      </div>
                    </div>

                    <div className="bg-[#FDE9EC] rounded-lg w-full h-12 mt-2 text-red-600 flex items-center justify-center gap-x-1 text-[12px]">
                      <BiTimeFive className="size-5" />
                      <h1 className="font-light">
                        Remaining Membership Period:{" "}
                        <b className="font-bold">
                          {getDaysRemainingMembership(
                            selected_activated?.expired_date
                          )}
                        </b>
                      </h1>
                    </div>
                  </div>

                  <Input
                    {...props}
                    label="Card Number"
                    name={"card_number"}
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                    placeholder={"Tap the card on the card reader"}
                    setInputValue={(e) => setFieldValue("card_number", e)}
                    star={true}
                    className={
                      "outline-none focus:outline-red-600 cursor-pointer"
                    }
                    onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  />
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => dispatch(setModalReset())}
                    color={"secondary"}
                  />
                  <MyButton label={"Save"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <ModalSuccessMembership />
    </>
  );
};

export default ModalConfirmation;
