import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { setInfo } from "../features/slice";
import { CgClose } from "react-icons/cg";

function Component() {
  const { info } = useSelector((state) => state.main);
  let dispatch = useDispatch();

  return info.message ? (
    <div className="transition-all">
      <Alert
        color={info.color}
        isOpen={!!info.message}
        className="transition-all"
      >
        <div className="flex gap-x-4 items-center transition-all">
          <CgClose
            onClick={() => dispatch(setInfo({}))}
            className="hover:bg-gray-100/20 hover:text-gray-500 hover:size-7 rounded-md p-0.5 cursor-pointer size-7 transition-all"
          />
          {info.message}
        </div>
      </Alert>
    </div>
  ) : null;
}

export default Component;
