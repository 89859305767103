import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  FiSearch,
  FiCheck,
  FiFile,
  FiDownload,
  FiHelpCircle,
  FiDisc,
  FiCircle,
  FiCheckCircle,
} from "react-icons/fi";
import AnimatedNumber from "animated-number-react";
import { ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";

import Table from "../../components/TableWithSelection";
import Loading from "../../components/Loading";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import ButtonWizard from "../../components/ButtonWizard";
import Input from "../../components/Input";
import Filter from "../../components/Filter";
import Modal from "../../components/Modal";

import Pill from "../../components/Pill";
import {
  getBillingSettlement,
  downloadBillingSettlement,
  refresh,
  setBillingFilter,
} from "../slices/billing";
import { endpointAdmin, endpointBilling } from "../../settings";
import {
  toMoney,
  dateTimeFormatterCell,
  isRangeToday,
  toSentenceCase,
  isRangeThisMonth,
  isRangeMonthAdmin,
} from "../../utils";
import { get, post, getFile, postMultipart } from "../slice";
import DateRangeFilter from "../../components/DateRangeFilter";
import ModalWizardBilling from "./components/ModalWizardBilling";

const formatValue = (value) => toMoney(value.toFixed(0));

function Component({ view, canUpdate, canDelete, canAdd }) {
  const { auth } = useSelector((state) => state);
  const { loading, settlement, refreshToggle, billingFilter } = useSelector(
    (state) => state.billing
  );
  const {
    building_id: building,
    building_label,
    released,
    status: settled,
    status_label,
    settlementStart,
    settlementEnd,
    startDate,
    endDate,
  } = useSelector((state) => state.billing.billingFilter);

  const templateLink = auth.user.settlement_bulk_template;

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(5);

  const [buildingName, setBuildingName] = useState("");
  const [buildings, setBuildings] = useState("");

  const [info, setInfo] = useState({});

  const [settleModal, setSettleModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const fileInput = useRef();
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadModalByRefCode, setUploadModalByRefCode] = useState(false);
  const [uploadResult, setUploadResult] = useState(false);
  const [uploadResultByRefCode, setUploadResultByRefCode] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);

  const today = moment().format("yyyy-MM-DD", "day");
  const monthStart = moment().startOf("month").format("yyyy-MM-DD");
  const monthStartAdmin = moment().subtract("month", 3).format("yyyy-MM-DD");
  const monthEnd = moment().endOf("month").format("yyyy-MM-DD");
  const [openWizard, setOpenWizard] = useState(false);

  let dispatch = useDispatch();

  const getSum = (items) => {
    return items.reduce((sum, el) => {
      return sum + el.selling_price;
    }, 0);
  };

  const columns = useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      {
        Header: "Ref Code",
        accessor: (row) => (
          <Link
            class="Link"
            to={"/" + auth.role + "/billing/settlement/" + row.trx_code}
          >
            <b>{row.trx_code}</b>
          </Link>
        ),
      },
      {
        Header: "Unit",
        accessor: (row) => (
          <>
            {toSentenceCase(row.section_type)}{" "}
            {toSentenceCase(row.section_name)} {row.number}
          </>
        ),
      },
      { Header: "Building", accessor: "building_name" },
      { Header: "Amount", accessor: (row) => toMoney(row.selling_price) },
      {
        Header: "Settled",
        accessor: (row) =>
          auth.role === "bm" ? (
            row.payment_settled === 1 ? (
              <Pill color="success">Settled</Pill>
            ) : (
              <Pill color="secondary">Unsettled</Pill>
            )
          ) : row.payment_settled_date ? (
            <Pill color="success">Settled</Pill>
          ) : (
            <Pill color="secondary">Unsettled</Pill>
          ),
      },
      {
        Header: "Settlement Date",
        accessor: (row) =>
          auth.role === "bm"
            ? row.payment_settled_date
              ? dateTimeFormatterCell(row.payment_settled_date)
              : "- "
            : row.payment_settled_date
            ? dateTimeFormatterCell(row.payment_settled_date)
            : "-",
      },
    ],
    [auth]
  );

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildings(formatted);
          }
        )
      );
  }, [dispatch, limit, search]);

  useEffect(() => {
    if (search.length === 0) setLimit(5);
  }, [search]);

  useEffect(() => {
    dispatch(
      get(endpointBilling + "/management/billing/statistic", (res) => {
        setInfo(res.data.data);
      })
    );
  }, [dispatch]);

  return (
    <div>
      <Breadcrumb title="Settlement" />
      <h2 className="PageTitle">Billing Settlement</h2>

      <ModalWizardBilling
        openWizard={openWizard}
        setOpenWizard={setOpenWizard}
      />

      <Modal
        isOpen={uploadModal}
        toggle={() => {
          setUploadResult();
          setUploadModal(false);
        }}
        title="Upload Settlement"
        subtitle="Upload file type .xlsx for bulk settlement"
        okLabel={uploadResult ? "Done" : "Submit"}
        disablePrimary={loading}
        disableSecondary={loading}
        onClick={
          uploadResult
            ? () => {
                setUploadResult("");
                setUploadModal(false);
              }
            : () => {
                setLoadingUpload(true);

                let formData = new FormData();
                formData.append("file", fileUpload);

                dispatch(
                  postMultipart(
                    endpointBilling +
                      "/management/billing/settlement/bulksettlement",
                    formData,
                    (res) => {
                      setLoadingUpload(false);
                      setUploadResult(res.data.data);
                    },
                    (err) => {
                      setLoadingUpload(false);
                    }
                  )
                );
              }
        }
      >
        {uploadResult ? (
          <div style={{ maxHeight: "600px", overflow: "scroll" }}>
            <ListGroup style={{ marginBottom: "15px" }}>
              <div style={{ padding: "5px" }}>
                <b>
                  Settled Transaction:
                  {uploadResult.settled !== null ? (
                    <span style={{ color: "green" }}>
                      {uploadResult.settled.length + " "}
                      result{uploadResult.settled.length > 1 ? "s" : ""}
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
            <ListGroup style={{ marginBottom: "15px" }}>
              <div style={{ padding: "5px" }}>
                <b>
                  Already Settled Transaction:
                  {uploadResult.already_settled !== null ? (
                    <span style={{ color: "green" }}>
                      {uploadResult.count_already_settled + " "}
                      result{uploadResult.count_already_settled > 1 ? "s" : ""}
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
            <ListGroup>
              <div style={{ padding: "5px" }}>
                <b>
                  Unsettled Transaction:
                  {uploadResult.unsettled !== null ? (
                    <span style={{ color: "red" }}>
                      {uploadResult.unsettled.length + " "}
                      result{uploadResult.unsettled.length > 1 ? "s" : ""}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
          </div>
        ) : (
          <Loading loading={loadingUpload}>
            <input
              className="d-block"
              ref={fileInput}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={(e) => {
                setFileUpload(fileInput.current.files[0]);
              }}
            />
            <button
              onClick={() => {
                setLoadingUpload(true);
                dispatch(
                  getFile(
                    templateLink,
                    "billing_settlement_template.csv",
                    (res) => {
                      setLoadingUpload(false);
                    },
                    (err) => {
                      setLoadingUpload(false);
                    }
                  )
                );
              }}
              style={{
                marginTop: 16,
                color: "white",
              }}
            >
              Download Template
            </button>
          </Loading>
        )}
      </Modal>
      <Modal
        isOpen={uploadModalByRefCode}
        toggle={() => {
          setUploadResultByRefCode();
          setUploadModalByRefCode(false);
        }}
        title="Upload Settlement By RefCode"
        subtitle="Upload file type .xlsx for bulk settlement by refcode"
        okLabel={uploadResultByRefCode ? "Done" : "Submit"}
        disablePrimary={loading}
        disableSecondary={loading}
        onClick={
          uploadResultByRefCode
            ? () => {
                setUploadResultByRefCode("");
                setUploadModalByRefCode(false);
              }
            : () => {
                setLoadingUpload(true);

                let formData = new FormData();
                formData.append("file", fileUpload);

                dispatch(
                  postMultipart(
                    endpointBilling +
                      "/management/billing/settlement/billings-bulkie",
                    formData,
                    (res) => {
                      setLoadingUpload(false);

                      setUploadResultByRefCode(res.data.data);
                    },
                    (err) => {
                      setLoadingUpload(false);
                    }
                  )
                );
              }
        }
      >
        {uploadResultByRefCode ? (
          <div style={{ maxHeight: "600px", overflow: "scroll" }}>
            <ListGroup style={{ marginBottom: "15px" }}>
              <div style={{ padding: "5px" }}>
                <b>
                  Settled Transaction:
                  {uploadResultByRefCode.settled !== null ? (
                    <span style={{ color: "green" }}>
                      {uploadResultByRefCode.settled.length + " "}
                      result
                      {uploadResultByRefCode.settled.length > 1 ? "s" : ""}
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
            <ListGroup style={{ marginBottom: "15px" }}>
              <div style={{ padding: "5px" }}>
                <b>
                  Already Settled Transaction:
                  {uploadResultByRefCode.already_settled !== null ? (
                    <span style={{ color: "green" }}>
                      {uploadResultByRefCode.count_already_settled + " "}
                      result
                      {uploadResultByRefCode.count_already_settled > 1
                        ? "s"
                        : ""}
                    </span>
                  ) : (
                    <span style={{ color: "green" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
            <ListGroup>
              <div style={{ padding: "5px" }}>
                <b>
                  Unsettled Transaction:
                  {uploadResultByRefCode.unsettled !== null ? (
                    <span style={{ color: "red" }}>
                      {uploadResultByRefCode.unsettled.length + " "}
                      result
                      {uploadResultByRefCode.unsettled.length > 1 ? "s" : ""}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>0 result</span>
                  )}
                </b>
              </div>
            </ListGroup>
          </div>
        ) : (
          <Loading loading={loadingUpload}>
            <input
              className="d-block"
              ref={fileInput}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={(e) => {
                setFileUpload(fileInput.current.files[0]);
              }}
            />
            <button
              onClick={() => {
                setLoadingUpload(true);
                dispatch(
                  getFile(
                    templateLink,
                    "billing_settlement_template.csv",
                    (res) => {
                      setLoadingUpload(false);
                    },
                    (err) => {
                      setLoadingUpload(false);
                    }
                  )
                );
              }}
              style={{
                marginTop: 16,
                color: "white",
              }}
            >
              Download Template
            </button>
          </Loading>
        )}
      </Modal>
      <Modal
        isOpen={settleModal}
        toggle={() => {
          setSettleModal(!settleModal);
        }}
        title="Settlement Selection"
        okLabel="Settle"
        onClick={() => {
          dispatch(
            post(
              endpointBilling + "/management/billing/settlement",
              {
                trx_code: selected.map((el) => el.trx_code),
              },
              (res) => {
                dispatch(refresh());
                setSettleModal(false);
                dispatch(
                  get(
                    endpointBilling + "/management/billing/settlement/info",
                    (res) => {
                      setInfo(res.data.data);
                    }
                  )
                );
              }
            )
          );
        }}
      >
        <div
          style={{
            minHeight: 300,
          }}
        >
          {selected.map((el) => (
            <div
              key={el.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: 8,
                marginBottom: 4,
                border: "1px solid silver",
                borderRadius: 4,
              }}
            >
              <div>
                <div>Trx Code</div>
                {el.trx_code}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                {toMoney(el.selling_price)}
              </div>
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: 16,
          }}
        >
          <h5>Total {toMoney(getSum(selected))}</h5>
        </div>
      </Modal>
      <div className="flex bg-white shadow-yipy w-full px-5 py-4 rounded-xl mb-4 flex-1">
        <div className="flex flex-col flex-1">
          <div className="flex items-center gap-x-4 text-[16px]">
            <h1 className="font-bold">Unsettled Amount</h1>
            <AnimatedNumber
              className="BigNumber"
              value={info.total_unsettle_amount}
              formatValue={formatValue}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex items-center gap-x-4 text-[16px]">
            <h1 className="font-bold">Settled Amount</h1>
            <AnimatedNumber
              className="BigNumber"
              value={info.total_settle_amount}
              formatValue={formatValue}
            />
          </div>
        </div>
      </div>
      <div className="Container">
        <Table
          onSelection={(selectedRows) => {
            setSelected(
              selectedRows.filter((el) => el && !el.payment_settled_date)
            );
          }}
          totalItems={settlement.total_items}
          columns={columns}
          data={settlement.items}
          loading={loading}
          pageCount={settlement.total_pages}
          fetchData={useCallback(
            (pageIndex, pageSize, search) => {
              dispatch(
                getBillingSettlement(
                  pageIndex,
                  pageSize,
                  search,
                  building,
                  settled,
                  ...(auth.role === "sa"
                    ? [settlementStart, settlementEnd]
                    : auth.role === "bm"
                    ? [settlementStart, settlementEnd]
                    : [today, today])
                )
              );
              // eslint-disable-next-line react-hooks/exhaustive-deps
            },
            [
              dispatch,
              auth.role,
              refreshToggle,
              building,
              settled,
              settlementStart,
              settlementEnd,
              startDate,
              endDate,
            ]
          )}
          filters={
            auth.role === "sa"
              ? [
                  {
                    hidex: isRangeThisMonth(settlementStart, settlementEnd),
                    label: "Date: ",
                    delete: () => {
                      dispatch(
                        setBillingFilter({
                          ...billingFilter,
                          settlementStart: monthStart,
                          settlementEnd: monthEnd,
                        })
                      );
                    },
                    value: isRangeToday(settlementStart, settlementEnd)
                      ? "Today"
                      : moment(settlementStart).format("DD-MM-yyyy") +
                        " - " +
                        moment(settlementEnd).format("DD-MM-yyyy"),
                    component: (toggleModal) => (
                      <DateRangeFilter
                        startDate={monthStart}
                        endDate={monthEnd}
                        onApply={(start, end) => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              settlementStart: start,
                              settlementEnd: end,
                            })
                          );
                          toggleModal();
                        }}
                      />
                    ),
                  },
                  {
                    hidex: settled === "",
                    label: "Status: ",
                    value: settled ? status_label : "All",
                    delete: () => {
                      dispatch(
                        setBillingFilter({
                          ...billingFilter,
                          status: "",
                          status_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <Filter
                        data={[
                          { value: "0", label: "Unsettled" },
                          { value: "1", label: "Settled" },
                        ]}
                        onClick={(el) => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              status: el.value,
                              status_label: el.label,
                            })
                          );
                          toggleModal(false);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              status: "",
                              status_label: "",
                            })
                          );
                          toggleModal(false);
                        }}
                      />
                    ),
                  },
                  {
                    hidex: building === "",
                    label: "Building: ",
                    value: building ? building_label : "All",
                    delete: () => {
                      dispatch(
                        setBillingFilter({
                          ...billingFilter,
                          building_id: "",
                          building_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <>
                        <Input
                          label="Search"
                          compact
                          icon={<FiSearch />}
                          inputValue={search}
                          setInputValue={setSearch}
                        />
                        <Filter
                          data={buildings}
                          onClick={(el) => {
                            if (!el.value) {
                              setLimit(limit + el.restTotal);
                              return;
                            }
                            dispatch(
                              setBillingFilter({
                                ...billingFilter,
                                building_id: el.value,
                                building_label: el.label,
                              })
                            );
                            toggleModal(false);
                            setSearch("");
                          }}
                          onClickAll={() => {
                            dispatch(
                              setBillingFilter({
                                ...billingFilter,
                                building_id: "",
                                building_label: "",
                              })
                            );
                            toggleModal(false);
                            setSearch("");
                          }}
                        />
                      </>
                    ),
                  },
                ]
              : [
                  {
                    hidex: isRangeThisMonth(settlementStart, settlementEnd),
                    label: "Date: ",
                    delete: () => {
                      dispatch(
                        setBillingFilter({
                          ...billingFilter,
                          settlementStart: monthStart,
                          settlementEnd: monthEnd,
                        })
                      );
                    },
                    value: isRangeToday(settlementStart, settlementEnd)
                      ? "Today"
                      : moment(settlementStart).format("DD-MM-yyyy") +
                        " - " +
                        moment(settlementEnd).format("DD-MM-yyyy"),
                    component: (toggleModal) => (
                      <DateRangeFilter
                        startDate={monthStart}
                        endDate={monthEnd}
                        onApply={(start, end) => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              settlementStart: start,
                              settlementEnd: end,
                            })
                          );
                          toggleModal();
                        }}
                      />
                    ),
                  },
                  {
                    hidex: settled === "",
                    label: "Status: ",
                    value: settled ? status_label : "All",
                    delete: () => {
                      dispatch(
                        setBillingFilter({
                          ...billingFilter,
                          status: "",
                          status_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <Filter
                        data={[
                          { value: "0", label: "Unsettled" },
                          { value: "1", label: "Settled" },
                        ]}
                        onClick={(el) => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              status: el.value,
                              status_label: el.label,
                            })
                          );
                          toggleModal(false);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              status: "",
                              status_label: "",
                            })
                          );
                          toggleModal(false);
                        }}
                      />
                    ),
                  },
                ]
          }
          actionDownloads={
            view
              ? null
              : [
                  <ButtonWizard
                    color="Download"
                    icon={<FiHelpCircle style={{ fontSize: "16px" }} />}
                    onClick={() => {
                      setOpenWizard(true);
                    }}
                  />,
                  <Button
                    fontWeight={500}
                    color="Download"
                    label="Download.csv"
                    icon={<FiDownload />}
                    onClick={() =>
                      dispatch(
                        downloadBillingSettlement(
                          search,
                          building,
                          settled,
                          settlementStart,
                          settlementEnd
                        )
                      )
                    }
                  />,
                ]
          }
          renderActions={(selectedRowIds, page) => {
            return [
              view
                ? null
                : auth.role === "sa" && (
                    <div style={{ display: "flex", columnGap: 3 }}>
                      <Button
                        disabled={Object.keys(selectedRowIds).length === 0}
                        onClick={() => {
                          setSettleModal(true);
                        }}
                        icon={<FiCheck />}
                        label="Settle"
                      />
                      <Button
                        onClick={() => {
                          setUploadModal(true);
                        }}
                        icon={<FiFile />}
                        label="Upload Settlement"
                      />
                      <Button
                        onClick={() => {
                          setUploadModalByRefCode(true);
                        }}
                        icon={<FiFile />}
                        label="Upload Settlement By Refcode"
                      />
                    </div>
                  ),
            ];
          }}
        />
      </div>
    </div>
  );
}

export default Component;

// useEffect(() => {
//   console.log(columns);
//   if (auth.role === "bm") {
//     console.log(columns);
//     return;
//   }
// }, [auth.role, columns]);
