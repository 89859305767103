import { createSlice } from "@reduxjs/toolkit";
import { endpointBookingFacility, endpointResident } from "../../settings";
import { get, post, put, del, setInfo, getFile } from "../slice";
import { toast } from "sonner";

export const slice = createSlice({
  name: "membership",
  initialState: {
    loading: false,
    items: [],
    selected: {},
    selected_activated: {},
    member_selected: {},
    total_items: 0,
    total_pages: 1,
    page: 1,
    range: 10,
    search: "",
    refreshToggle: true,
    facilityOptions: [],
    isLogin: false,
    formPayloadMembership: {
      resident_id: "",
      facility_id: "",
      package_id: "",
      email: "",
      phone: "",
      firstname: "",
      lastname: "",
      card_number: "",
      building_id: null,
    },
    log: {
      items: [],
      total_items: 0,
      total_pages: 0,
      limit: 10,
      filtered_page: 1,
      filtered_item: 1,
      search: "",
    },
    membershipFilter: {
      buildingFilter: "",
      buildingFilter_label: "",
      packageFilter: "",
      packageFilter_label: "",
      facilityFilter: "",
      facilityFilter_label: "",
      statusFilter: "",
      statusFilter_label: "",
    },
    modal: {
      addMembership: false,
      setAsPaid: false,
      changeCardNumber: false,
      activeMembership: false,
      extendMembership: false,
      reActivateMembership: false,
      deleteMembership: false,
      editMembership: false,
      confirmation: false,
      seeHistory: false,
      success: false,
      help: false,
      setInactiveMembership: false,
      checkinMembership: false,
      checkoutMembership: false,
      message: "",
    },
  },
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setFormPayloadMembership: (state, action) => {
      state.formPayloadMembership = action.payload;
    },
    startAsync: (state) => {
      state.loading = true;
    },
    stopAsync: (state) => {
      state.loading = false;
    },
    setData: (state, action) => {
      const data = action.payload;

      state.items = data.items;
      state.total_items = data.total_item;
      state.total_pages = data.total_page;
    },
    setDataLog: (state, action) => {
      const data = action.payload;

      state.log.items = data.items;
      state.log.total_items = data.total_item;
      state.log.total_pages = data.total_page;
    },
    setSelected: (state, action) => {
      Object.assign(state, action.payload);
    },
    setSelectedActivated: (state, action) => {
      state.selected_activated = action.payload;
    },
    refresh: (state) => {
      state.refreshToggle = !state.refreshToggle;
    },
    setModalMembership: (state, action) => {
      state.modal = {
        ...action.payload,
      };
    },
    setFacilityOptions: (state, action) => {
      const data = action.payload;
      let formatted = data.map((el) => ({
        label: el.name,
        value: el.id,
      }));

      state.facilityOptions = formatted;
    },
    setModalReset: (state, action) => {
      state.modal = {
        addMembership: false,
        setAsPaid: false,
        changeCardNumber: false,
        activeMembership: false,
        extendMembership: false,
        reActivateMembership: false,
        deleteMembership: false,
        editMembership: false,
        confirmation: false,
        success: false,
        help: false,
        seeHistory: false,
        setInactiveMembership: false,
        checkinMembership: false,
        checkoutMembership: false,
        // message: "",
      };
      state.selected = {};
      // state.selected_activated = {};
      state.formPayloadMembership = {
        facility_id: "",
        package_id: "",
        email: "",
        phone: "",
        firstname: "",
        lastname: "",
        card_number: "",
        building_id: null,
      };
    },
    setMembershipFilter: (state, action) => {
      state.membershipFilter = {
        packageFilter: "",
        packageFilter_label: "",
        facilityFilter: "",
        facilityFilter_label: "",
        statusFilter: "",
        statusFilter_label: "",
        ...action.payload,
      };
    },
  },
});

export const getLogActivity =
  (pageIndex, pageSize, search = search, building = "") =>
  (dispatch) => {
    dispatch(startAsync());

    dispatch(
      get(
        endpointBookingFacility +
          "/admin/activity/logs?&page=" +
          (pageIndex + 1) +
          "&limit=" +
          pageSize +
          "&search=" +
          search +
          "&building_id=" +
          building,
        (res) => {
          dispatch(setData(res.data.data));
          dispatch(stopAsync());
        },
        (err) => {
          dispatch(stopAsync());
        }
      )
    );
  };

export const getActivityList =
  (pageIndex, pageSize, facility, packageId, search, status) => (dispatch) => {
    dispatch(startAsync());

    dispatch(
      get(
        endpointBookingFacility +
          "/admin/memberships?page=" +
          (pageIndex + 1) +
          "&limit=" +
          pageSize +
          "&facility_id=" +
          facility +
          "&package_id=" +
          packageId +
          "&search=" +
          search +
          "&status=" +
          status,
        (res) => {
          dispatch(setData(res.data.data));
          dispatch(stopAsync());
        },
        (err) => {
          dispatch(stopAsync());
        }
      )
    );
  };

export const checkingCardMember =
  (data, setCardValid, setCardInvalid, setCardData) => (dispatch) => {
    dispatch(startAsync());
    dispatch(setSelected({ member_selected: {} }));

    dispatch(
      post(
        endpointBookingFacility + "/admin/activity/login",
        data,
        (res) => {
          setCardData(res.data.data);
          dispatch(setIsLogin(true));
          dispatch(setSelected({ member_selected: res.data.data }));
          toast.success("Member Check Success");
          setCardValid(true);
          dispatch(stopAsync());
        },
        (err) => {
          setCardData();
          setCardInvalid(false);
          dispatch(setIsLogin(false));
          toast.error(err?.response?.data?.error);
          dispatch(stopAsync());
        }
      )
    );
  };

export const checkInConfirmation =
  (data, id, setCardValid, setCardInvalid) => (dispatch) => {
    dispatch(startAsync());

    dispatch(
      post(
        endpointBookingFacility + "/admin/activity/check-in/" + id,
        data,
        (res) => {
          toast.success("Access Member Valid");
          setCardValid(false);
          setCardInvalid(false);
          dispatch(setIsLogin(false));
          dispatch(setSelected({ member_selected: {} }));
          dispatch(setModalReset());
          dispatch(stopAsync());
        },
        (err) => {
          dispatch(setModalReset());
          setCardValid(false);
          setCardInvalid(false);
          toast.error(err?.response?.data?.error);
          dispatch(stopAsync());
        }
      )
    );
  };

export const checkOutConfirmation =
  (data, id, setCardValid, setCardInvalid) => (dispatch) => {
    dispatch(startAsync());

    dispatch(
      post(
        endpointBookingFacility + "/admin/activity/check-out/" + id,
        data,
        (res) => {
          toast.success("Access Card Success to Check Out");
          setCardValid(false);
          setCardInvalid(false);
          dispatch(setIsLogin(false));
          dispatch(setSelected({ member_selected: {} }));
          dispatch(setModalReset());
          dispatch(stopAsync());
        },
        (err) => {
          dispatch(setModalReset());
          setCardValid(false);
          setCardInvalid(false);
          toast.error(err?.response?.data?.error);
          dispatch(stopAsync());
        }
      )
    );
  };

export const checkingMemberValidation = (data) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    post(
      endpointResident + "/members/validate",
      data,
      (res) => {
        dispatch(setSelected({ member_selected: res.data }));
        toast.success("Member Already Registered");
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const createMembership = (data) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    post(
      endpointBookingFacility + "/admin/memberships",
      data,
      (res) => {
        toast.success("Success Create Membership");
        dispatch(refresh());
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const updateMembership = (id, endpoint, data, message) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    put(
      endpointBookingFacility + `/admin/memberships/edit/${id}/${endpoint}`,
      data,
      (res) => {
        dispatch(refresh());
        dispatch(setModalReset());
        dispatch(setIsLogin(false));
        dispatch(setModalMembership({ success: true, message: message }));
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const updateDataMembership = (id, data) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    put(
      endpointBookingFacility + `/admin/memberships/edit/${id}`,
      data,
      (res) => {
        dispatch(refresh());
        dispatch(setModalReset());
        dispatch(setModalMembership({ success: true, message: "update data" }));
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const updateDeleteMembership = (id) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    del(
      endpointBookingFacility + `/admin/memberships/${id}`,
      (res) => {
        dispatch(refresh());
        dispatch(setModalReset());
        dispatch(setIsLogin(false));
        dispatch(setModalMembership({ success: true, message: "delete" }));
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const editMembershipForm = (id, row) => (dispatch) => {
  dispatch(setModalReset());
  dispatch(setSelected({ selected: row }));
  dispatch(
    get(endpointBookingFacility + "/admin/memberships/detail/" + id, (res) => {
      let data = res?.data?.data;
      dispatch(
        setFormPayloadMembership({
          resident_id: data.resident.id,
          building_id: data.building_id,
          facility_id: data.facility.id,
          package_id: data.package.id,
          email: data.resident.email,
          phone: data.resident.phone,
          firstname: data.resident.name.split(" ").at(0),
          lastname: data.resident.name.split(" ").at(1),
          card_number: data.access_card.card_number,
        })
      );
      dispatch(setModalMembership({ editMembership: true }));
    })
  );
};

export const getDetailMembership = (id) => (dispatch) => {
  dispatch(
    get(endpointBookingFacility + "/admin/memberships/detail/" + id, (res) => {
      let data = res?.data?.data;
      dispatch(setSelectedActivated(data));
    })
  );
};

export const {
  startAsync,
  stopAsync,
  setData,
  setDataLog,
  setSelected,
  setIsLogin,
  setFacilityOptions,
  refresh,
  setMembershipFilter,
  setModalMembership,
  setModalReset,
  setFormPayloadMembership,
  setSelectedActivated,
} = slice.actions;

export default slice.reducer;
