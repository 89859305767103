import React from "react";
import { BiCheckCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../../../../components/Button";
import { setModalReset } from "../../../../slices/membership";
import { ModalBody, Modal as ModalReact } from "reactstrap";

const ModalSuccessMembership = () => {
  const { success, message } = useSelector((state) => state.membership.modal);
  const dispatch = useDispatch();
  return (
    <>
      {/* Modal Success */}
      <ModalReact
        modalTransition={{ timeout: 1 }}
        backdropTransition={{ timeout: 1 }}
        isOpen={success}
        toggle={() => dispatch(setModalReset())}
      >
        <ModalBody>
          <div className="flex flex-col w-full justify-center items-center h-full p-4 gap-y-1">
            <BiCheckCircle className="text-green-600 size-20" />
            <div className="flex flex-col py-4 gap-y-2 items-center">
              <h1 className="text-[22px] font-bold capitalize">
                Successfully {message || "Process"}!
              </h1>
              <h1 className="text-gray-400">
                You have successfully {message || "process"} the member
              </h1>
            </div>

            <MyButton
              label={"Back to Membership List"}
              btnType={"button"}
              onClick={() => dispatch(setModalReset())}
            />
          </div>
        </ModalBody>
      </ModalReact>
    </>
  );
};

export default ModalSuccessMembership;
