import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  FiSearch,
  FiDownload,
  FiUpload,
  FiHelpCircle,
  FiCheckCircle,
  FiDisc,
  FiCircle,
} from "react-icons/fi";

import Input from "../../components/Input";
import Filter from "../../components/Filter";
import Button from "../../components/Button";
import { ListGroup, ListGroupItem } from "reactstrap";
import {
  getBillingCategory,
  downloadBillingCategory,
  setSelectedItem,
  setSelected,
  refresh,
  stopAsync,
  setBillingFilter,
} from "../slices/billing";
import { endpointAdmin, endpointBilling } from "../../settings";
import { toSentenceCase, toMoney } from "../../utils";
import { get, getFile, post, setInfo } from "../slice";

import Template from "./components/Template";
import UploadModal from "../../components/UploadModal";
import Modal from "../../components/Modal";
import ButtonWizard from "../../components/ButtonWizard";
import ModalWizardBillingCategory from "./components/ModalWizardBillingCategory";

function Component({ view, canAdd }) {
  const [search, setSearch] = useState("");

  const { role, user } = useSelector((state) => state.auth);
  const { billingFilter } = useSelector((state) => state.billing);
  const { building_category: building, building_category_label: buildingName } =
    useSelector((state) => state.billing.billingFilter);

  const [buildings, setBuildings] = useState("");

  const [limit, setLimit] = useState(5);
  const [upload, setUpload] = useState(false);
  const [download, setDownload] = useState(false);
  const [openWizard, setOpenWizard] = useState(false);
  const [buildingRelease, setBuildingRelease] = useState("");
  const [buildingList, setBuildingList] = useState("");

  let dispatch = useDispatch();
  let history = useHistory();
  let { url } = useRouteMatch();

  const yearnow = new Date().getFullYear();
  const years = [];

  for (let i = yearnow - 2; i <= yearnow + 2; i++) {
    years.push({ value: i, label: i });
  }

  const [year, setYear] = useState(yearnow);

  const monthnow = new Date().getMonth();
  const months = [
    { value: 0, label: "Full Year" },
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const [month, setMonth] = useState(monthnow + 1);

  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/building" +
          "?limit=" +
          9999 +
          "&page=1" +
          "&search=" +
          search,
        (res) => {
          let data = res.data.data.items;

          let formatted = data.map((el) => ({
            label: el.name,
            value: el.id,
          }));

          setBuildingList(formatted);
        }
      )
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/building" +
          "?limit=" +
          limit +
          "&page=1" +
          "&search=" +
          search,
        (res) => {
          let data = res.data.data.items;
          let totalItems = Number(res.data.data.total_items);
          let restTotal = totalItems - data.length;

          let formatted = data.map((el) => ({ label: el.name, value: el.id }));

          if (data.length < totalItems && search.length === 0) {
            formatted.push({
              label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
              restTotal: restTotal > 5 ? 5 : restTotal,
              className: "load-more",
            });
          }

          setBuildings(formatted);
        }
      )
    );
  }, [dispatch, search, limit]);

  useEffect(() => {
    if (search.length === 0) setLimit(5);
  }, [search]);

  const columns = [
    // { Header: 'ID', accessor: 'code' },
    { Header: "Unit ID", accessor: "id" },
    {
      Header: "Unit",
      accessor: (row) => (
        <span
          className="Link"
          onClick={() => {
            history.push("/" + role + "/billing/unit/" + row.id);
          }}
        >
          <b>
            {toSentenceCase(row.section_type) +
              " " +
              row.section_name +
              " " +
              row.number}
          </b>
          <br />
          <span>{row.building_name}</span>
        </span>
      ),
    },
    // {
    //   Header: "Building",
    //   accessor: (row) => (
    //     <a className="Link" href={"/" + role + "/building/" + row.building_id}>
    //       {row.building_name}
    //     </a>
    //   ),
    // },
    {
      Header: "Resident",
      accessor: (row) =>
        row.resident_name ? toSentenceCase(row.resident_name) : "-",
    },
    { Header: "Month", accessor: (row) => row.month },
    { Header: "Year", accessor: (row) => row.year },
    { Header: "Service Name", accessor: (row) => row.service_name },
    { Header: "Paid Amount", accessor: (row) => toMoney(row.paid) },
    { Header: "Unpaid Amount", accessor: (row) => toMoney(row.unpaid) },
    // {
    //   Header: "Additional Charges",
    //   accessor: (row) => toMoney(row.additional_charge),
    // },
    // { Header: "Penalty", accessor: (row) => toMoney(row.billing_penalty) },
    {
      Header: "Total Amount",
      accessor: (row) => <b>{toMoney(row.total_all)}</b>,
    },
  ];

  function uploadResult(result) {
    return (
      <>
        <div>
          <h5>Total of {result.main_billings_total} billings was read</h5>
        </div>
        <hr />
        <ListGroup style={{ marginBottom: "20px" }}>
          <ListGroupItem color="success">
            {result.main_billings_success} billings successfully created.
          </ListGroupItem>
          <ListGroupItem color="danger">
            {result.main_billings_failed} billings failed to create.
          </ListGroupItem>
        </ListGroup>
        <ListGroup>
          <p>Showing up to 100 result: </p>
          {result.main_billings?.map((el, index) => {
            /* only show up to 10 rows */
            return (
              <>
                {index < 100 && (
                  <ListGroupItem
                    color={
                      !el.row_error && !el.column_error ? "success" : "danger"
                    }
                  >
                    {!el.row_error && !el.column_error ? (
                      <>
                        Row {el.row_number}: <b>{el.data?.name}</b> for unit ID{" "}
                        <b>{el.data?.resident_unit}</b> was created{" "}
                        <b>(ID: {el.data?.id})</b>.
                      </>
                    ) : (
                      <>
                        Row {el.row_number}:{" "}
                        {el.row_error && <>{el.row_error},</>}{" "}
                        {el.column_error &&
                          el.column_error.map(
                            (k) => k.column_name + " " + k.error_message
                          )}
                      </>
                    )}
                  </ListGroupItem>
                )}
              </>
            );
          })}
        </ListGroup>
        <div style={{ marginTop: "20px" }}>
          <h5>
            Total of {result.additional_charges_total} additional charges was
            read
          </h5>
        </div>
        <hr />
        <ListGroup style={{ marginBottom: "20px" }}>
          <ListGroupItem color="success">
            {result.additional_charges_success} additional charges successfully
            created.
          </ListGroupItem>
          <ListGroupItem color="danger">
            {result.additional_charges_failed} additional charges failed to
            create.
          </ListGroupItem>
        </ListGroup>
        <p>Showing up to 100 result: </p>
        {result.additional_charges?.map((el, index) => {
          /* only show up to 10 rows */
          return (
            <>
              {index < 100 && (
                <ListGroupItem
                  color={
                    !el.row_error && !el.column_error ? "success" : "danger"
                  }
                >
                  {!el.row_error && !el.column_error ? (
                    <>
                      Row {el.row_number}: <b>{el.data?.charge_name}</b> for
                      billing ID <b>{el.data?.billing_id}</b> was created.
                    </>
                  ) : (
                    <>
                      Row {el.row_number}:{" "}
                      {el.row_error && <>{el.row_error},</>}{" "}
                      {el.column_error &&
                        el.column_error.map(
                          (k) => k.column_name + " " + k.error_message
                        )}
                    </>
                  )}
                </ListGroupItem>
              )}
            </>
          );
        })}
      </>
    );
  }

  return (
    <>
      <ModalWizardBillingCategory
        openWizard={openWizard}
        setOpenWizard={setOpenWizard}
      />
      <UploadModal
        open={upload}
        toggle={() => setUpload(false)}
        templateLink={user.billing_bulk_template}
        filename="billing_unit_template.xlsx"
        uploadLink={endpointBilling + "/management/billing/upload"}
        uploadDataName="file_upload"
        resultComponent={uploadResult}
      />
      <Modal
        isOpen={download}
        toggle={() => {
          setDownload(false);
        }}
        title="Download Billing Category"
        okLabel={"Download"}
        onClick={() => {
          dispatch(
            getFile(
              endpointBilling +
                "/management/billing/unit/category/v2?page=1&limit=9999999&sort_field=created_on&sort_type=DESC" +
                "&building_id=" +
                buildingRelease +
                "&search=" +
                search +
                "&month=" +
                month +
                "&year=" +
                year +
                "&export=true",
              "billing_category.csv",
              (res) => {
                dispatch(
                  setInfo({
                    color: "success",
                    message: `Billing category has been downloaded.`,
                  })
                );
                // resultComponent ? setOpenRes(true) : toggle();
              },
              (err) => {
                dispatch(
                  setInfo({
                    color: "error",
                    message: `Error to download.`,
                  })
                );
                console.log("error");
              }
            )
          );

          dispatch(refresh());
          dispatch(stopAsync());
          setDownload(false);
        }}
      >
        <Input
          label="Building"
          inputValue={buildingRelease}
          type="select"
          options={buildingList}
          setInputValue={setBuildingRelease}
          title="Building List"
          // hidden={role !== "sa"}
        />

        <Input
          label="Month"
          inputValue={month}
          type="select"
          options={months}
          setInputValue={setMonth}
          title="Month List"
        />

        <Input
          label="Year"
          inputValue={year}
          type="select"
          options={years}
          setInputValue={setYear}
          title="Year List"
        />
      </Modal>
      <Template
        pagetitle="Billing List Category"
        title="Category"
        columns={columns}
        slice="billing"
        getAction={getBillingCategory}
        filterVars={[building, upload, month, year]}
        filters={
          role === "sa"
            ? [
                {
                  hidex: building === "",
                  label: "Building: ",
                  value: building ? buildingName : "All",
                  delete: () => {
                    dispatch(
                      setBillingFilter({
                        ...billingFilter,
                        building_category: "",
                        building_category_label: "",
                      })
                    );
                  },
                  component: (toggleModal) => (
                    <>
                      <Input
                        label="Search"
                        compact
                        icon={<FiSearch />}
                        inputValue={search}
                        setInputValue={setSearch}
                      />
                      <Filter
                        data={buildings}
                        onClick={(el) => {
                          if (!el.value) {
                            setLimit(limit + el.restTotal);
                            return;
                          }
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              building_category: el.value,
                              building_category_label: el.label,
                            })
                          );
                          toggleModal(false);
                          setSearch("");
                          setLimit(5);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setBillingFilter({
                              ...billingFilter,
                              building_category: "",
                              building_category_label: "",
                            })
                          );
                          toggleModal(false);
                          setSearch("");
                          setLimit(5);
                        }}
                      />
                    </>
                  ),
                },
              ]
            : []
        }
        actionDownloads={
          view
            ? null
            : [
                <ButtonWizard
                  color="Download"
                  icon={<FiHelpCircle style={{ fontSize: "16px" }} />}
                  onClick={() => {
                    setOpenWizard(true);
                  }}
                />,
                <Button
                  fontWeight={500}
                  color="Download"
                  label="Download.csv"
                  icon={<FiDownload />}
                  onClick={() =>
                    // dispatch(downloadBillingCategory(search, building))
                    setDownload(true)
                  }
                />,
              ]
        }
        actions={
          view
            ? null
            : [
                role === "bm" && !canAdd ? null : (
                  <Button
                    label="Upload Bulk"
                    icon={<FiUpload />}
                    onClick={() => setUpload(true)}
                  />
                ),
              ]
        }
        onClickAddBilling={
          view
            ? null
            : role === "bm" && !canAdd
            ? null
            : (row) => {
                dispatch(setSelected(row));
                dispatch(setSelectedItem({}));
                history.push(url + "/" + row.id + "/add");
              }
        }
      />
    </>
  );
}

export default Component;
