import React, { useEffect } from "react";
import {
  FiUsers,
  FiBarChart2,
  FiShoppingCart,
  FiZap,
  FiVolume2,
  FiBriefcase,
  FiShoppingBag,
  FiWifi,
  FiFile,
  FiCalendar,
  FiBell,
  FiUserCheck,
  FiFeather,
} from "react-icons/fi";
import {
  RiStore2Line,
  RiTaskLine,
  RiBuilding2Line,
  RiCustomerService2Line,
  RiAdvertisementLine,
  RiCoupon2Line,
  RiBankCardLine,
  RiSurveyLine,
  RiFileTextLine,
} from "react-icons/ri";
import { FaIdBadge } from "react-icons/fa";

import Dashboard from "./Dashboard";
import Ads from "./Ads";
import Announcement from "./Announcement";
import Billing from "./Billing";
import Building from "./Building";
import Management from "./Management";
import Vouchers from "./Vouchers";
import PromoVA from "./PromoVA";
import Internet from "./Internet";
import Booking from "./Booking";
import Membership from "./Membership";
import Merchant from "./Merchant";
import Product from "./Product";
import Resident from "./Resident";
import RequestPremium from "./RequestPremium";
import CatatMeter from "./CatatMeter";
import Staff from "./Staff";
import Task from "./Task";
import UserRequest from "./UserRequest";
import Transaction from "./Transaction";
import Admin from "./Admin";
import Xenplatform from "./Xenplatform";
import PushNotif from "./PushNotif";

import Settings from "../../settings";
import Chat from "../../chat";

import Template from "../components/Template";
import { Route, Redirect, useLocation } from "react-router-dom";

import TokenThera from "./TokenThera";
import { useDispatch, useSelector } from "react-redux";
import { setDateFilter, setTaskFilter } from "../../slices/task";
import { setResidentFilter } from "../../slices/resident";

const menu = [
  // Overview Start
  {
    icon: <FiBarChart2 className="MenuItem-icon" />,
    label: "Dashboard",
    path: "/dashboard",
    subpaths: ["/building", "/transaction", "/task", "/advertisement", "/CCTV"],
    // subpaths: ["/building", "/transaction", "/task", "/advertisement"],
    component: <Dashboard />,
    group: "Overview",
  },
  {
    icon: <FiBriefcase className="MenuItem-icon" />,
    label: "Management",
    path: "/management",
    component: <Management />,
  },
  {
    icon: <RiBuilding2Line className="MenuItem-icon" />,
    label: "Building",
    path: "/building",
    component: <Building />,
    separator: true,
  },
  // Overview End

  // Building Start
  {
    icon: <FiUsers className="MenuItem-icon" />,
    label: "Resident",
    path: "/resident",
    component: <Resident />,
    group: "Building",
  },
  {
    icon: <RiCustomerService2Line className="MenuItem-icon" />,
    label: "Staff",
    path: "/staff",
    component: <Staff />,
  },
  {
    icon: <RiSurveyLine className="MenuItem-icon" />,
    label: "Task",
    path: "/task",
    component: <Task />,
  },
  {
    icon: <FiUserCheck className="MenuItem-icon" />,
    label: "Request Premium",
    path: "/request premium user",
    component: <RequestPremium />,
    separator: true,
  },
  // Building End

  // Billing Start
  {
    icon: <FiZap className="MenuItem-icon" />,
    label: "Billing",
    path: "/billing",
    subpaths: ["/unit", "/category", "/settlement", "/disbursement"],
    component: <Billing />,
    group: "Billing",
  },
  // {
  //   icon: <RiTaskLine className="MenuItem-icon" />,
  //   label: "XenPlatform",
  //   path: "/xenplatform",
  //   component: <Xenplatform />,
  // },

  {
    icon: <RiFileTextLine className="MenuItem-icon" />,
    label: "Catat Meter",
    path: "/catatmeter",
    component: <CatatMeter />,
    separator: true,
  },
  // Billing End

  // Promo Start
  {
    icon: <RiCoupon2Line className="MenuItem-icon" />,
    label: "Vouchers",
    path: "/vouchers",
    component: <Vouchers />,
    group: "Promo",
  },
  {
    icon: <RiBankCardLine className="MenuItem-icon" />,
    label: "Promo VA",
    path: "/promo VA",
    component: <PromoVA />,
    separator: true,
  },
  // Promo End

  // Shop Start
  {
    icon: <RiStore2Line className="MenuItem-icon" />,
    label: "Merchant",
    path: "/merchant",
    component: <Merchant />,
    group: "Shop",
  },
  {
    icon: <FiShoppingBag className="MenuItem-icon" />,
    label: "Product",
    path: "/product",
    component: <Product />,
  },
  {
    icon: <FiShoppingCart className="MenuItem-icon" />,
    label: "Transaction",
    path: "/transaction",
    subpaths: ["/list", "/settlement", "/disbursement"],
    component: <Transaction />,
    separator: true,
  },
  // Shop End

  // Features Start
  {
    icon: <FiWifi className="MenuItem-icon" />,
    label: "Internet",
    path: "/internet",
    component: <Internet />,
    group: "Features",
  },
  {
    icon: <FiCalendar className="MenuItem-icon" />,
    label: "Facility",
    path: "/facility",
    component: <Booking />,
  },
  {
    icon: <FiFeather className="MenuItem-icon" />,
    label: "Membership",
    path: "/membership",
    subpaths: ["/log", "/check-in"],
    component: <Membership />,
  },
  {
    icon: <FiFile className="MenuItem-icon" />,
    label: "User Request",
    path: "/user request",
    component: <UserRequest />,
  },
  {
    icon: <FiZap className="MenuItem-icon" />,
    label: "Token Thera",
    path: "/token-thera",
    subpaths: ["/transactions", "/settlement"],
    // subpaths: ["/transactions"],
    component: <TokenThera />,
    separator: true,
  },
  // Featires End

  // Information Start
  {
    icon: <RiAdvertisementLine className="MenuItem-icon" />,
    label: "Advertisement",
    path: "/advertisement",
    component: <Ads />,
    group: "Information",
  },
  {
    icon: <FiVolume2 className="MenuItem-icon" />,
    label: "Announcement",
    path: "/announcement",
    component: <Announcement />,
  },
  {
    icon: <FiBell className="MenuItem-icon" />,
    label: "Push Notification",
    path: "/push notification",
    component: <PushNotif />,
    separator: true,
  },
  // Information End

  // Settings Start
  {
    icon: <FaIdBadge className="MenuItem-icon" />,
    label: "Admin",
    path: "/admin",
    component: <Admin />,
    group: "Settings",
  },
  // Settings End
];

function Component() {
  return (
    <Template role="sa">
      <Redirect exact from={"/sa"} to={"/sa" + menu[0].path} />
      {menu.map((el) => (
        <Route
          key={el.label}
          label={el.label}
          icon={el.icon}
          path={"/sa" + el.path}
          subpaths={el.subpaths}
          group={el.group}
          separator={el.separator}
        >
          {el.component}
        </Route>
      ))}
      <Route path={"/sa/chat/:rf"}>
        <Chat />
      </Route>
      <Route path={"/sa/chat"}>
        <Chat />
      </Route>
      <Route path={"/sa/settings"}>
        <Settings />
      </Route>
    </Template>
  );
}

export default Component;
