import React, { useState } from "react";
import { FiGlobe, FiPhone, FiMail } from "react-icons/fi";
import { RiStore2Line } from "react-icons/ri";

import Loading from "../../../components/Loading";
import Tab from "../../../components/Tab";
import Breadcrumb from "../../../components/Breadcrumb";
import EmptyProfilePicture from "../../../assets/fallback.jpg";

function Component({
  image,
  title = "",
  website,
  phone,
  merchant,
  transparent = false,
  email,
  labels,
  contents,
  activeTab,
  pagetitle = "",
  imageTitle = "",
  loading = true,
  classNameContent,
}) {
  const [imgLoading, setImgLoading] = useState(true);

  return (
    <>
      <Breadcrumb title={title ? title : "Details"} />
      <h2 className="PageTitle">{pagetitle}</h2>
      <Loading loading={loading}>
        <div className="flex flex-1 gap-x-5 overflow-hidden">
          {image && (
            <div className="flex flex-col Container-ns h-fit max-w-[350px]">
              {imgLoading && (
                <div
                  style={{
                    height: 330,
                    objectFit: "cover",
                    width: "100%",
                    marginBottom: 16,
                  }}
                  className="shine"
                />
              )}

              <img
                alt="Avatar"
                src={image ? image : EmptyProfilePicture}
                style={{
                  height: imgLoading ? 0 : 330,
                  objectFit: "cover",
                  width: "100%",
                  marginBottom: 16,
                }}
                onLoad={() => setImgLoading(false)}
                onError={() => setImgLoading(false)}
              />
              {title && <h3 className="text-[20px] font-bold mb-4">{title}</h3>}
              {website && (
                <div className="flex">
                  <div className="col d-flex">
                    <FiGlobe className="mr-3 h4" />
                    <h5>
                      <a href={website}>{website}</a>
                    </h5>
                  </div>
                </div>
              )}
              {email && (
                <div className="flex">
                  <div className="col d-flex">
                    <FiMail className="mr-3 h4" />
                    <h5>
                      <a href={"mailto:" + email}>{email}</a>
                    </h5>
                  </div>
                </div>
              )}
              {phone && (
                <div className="flex">
                  <div className="col d-flex">
                    <FiPhone className="mr-3 h4" />
                    <h5>{"+" + phone}</h5>
                  </div>
                </div>
              )}
              {merchant && (
                <div className="flex">
                  <div className="col d-flex">
                    <RiStore2Line className="mr-3 h4" />
                    <h5>{merchant}</h5>
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={transparent ? "Container-transparent" : "Container-ns"}
          >
            <Tab labels={labels} contents={contents} activeTab={activeTab} />
          </div>
        </div>
      </Loading>
    </>
  );
}

export default Component;
