import { BsCardImage } from "react-icons/bs";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import MyButton from "../../../components/Button";
import TableMembership from "./components/TableMembership";
import { FiPlus, FiSearch } from "react-icons/fi";
import { membershipColumns } from "./utils/columns";
import Pill from "../../../components/Pill";
import { statusOptions } from "./utils/statics";
import {
  checkingCardMember,
  editMembershipForm,
  getDetailMembership,
  setData,
  setIsLogin,
  setMembershipFilter,
  setModalMembership,
  setModalReset,
  setSelected,
  setSelectedActivated,
} from "../../slices/membership";
import Filter from "../../../components/Filter";
import Input from "../../../features/form/inputBooking";
import InputSearch from "../../../components/Input";
import { Form, Formik } from "formik";
import ModalConfirmation from "./components/Modal/ModalConfirmation";
import ModalCreateMembership from "./components/Modal/ModalCreateMembership";
import { getAllFacilities } from "../../slices/facility";
import { dateFormaterEx, dateFormatter } from "../../../utils";
import { get } from "../../slice";
import { endpointAdmin, endpointBookingFacility } from "../../../settings";
import { toast } from "sonner";
import DummyPhoto from "./assets/yipy_logo_dummy.png";

function Component({ view, canAdd, canUpdate, canDelete }) {
  const {
    membershipFilter,
    facilityOptions,
    member_selected,
    items,
    total_items,
    total_pages,
    refreshToggle,
    selected,
    isLogin,
  } = useSelector((state) => state.membership);

  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);
  const [search, setSearch] = useState("");
  const [cardValid, setCardValid] = useState(false);
  const [cardInvalid, setCardInvalid] = useState(false);
  const [cardData, setCardData] = useState(member_selected || "");
  const [facilites, setFacilities] = useState([]);
  const [packages, setPackages] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  let dispatch = useDispatch();

  const {
    buildingFilter,
    buildingFilter_label,
    packageFilter,
    packageFilter_label,
    facilityFilter,
    facilityFilter_label,
    statusFilter,
    statusFilter_label,
  } = membershipFilter;

  useEffect(() => {
    dispatch(getAllFacilities(0, 100));
  }, []);

  // Building Options
  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/management/building" +
          "?limit=" +
          limit +
          "&page=1" +
          "&search=" +
          search,
        (res) => {
          let data = res.data.data.items;
          let totalItems = Number(res.data.data.total_items);
          let restTotal = totalItems - data.length;

          let formatted = data.map((el) => ({
            label: el.name,
            value: el.building_id,
          }));

          if (data.length < totalItems && search.length === 0) {
            formatted.push({
              label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
              restTotal: restTotal > 5 ? 5 : restTotal,
              className: "load-more",
            });
          }

          setBuildings(formatted);
        }
      )
    );
  }, [dispatch, limit, search]);

  // Facility Options based on Building ID
  useEffect(() => {
    dispatch(
      get(
        endpointBookingFacility + "/admin/facilities?limit=" + limit,
        (res) => {
          let packages = res.data.data.items;
          let totalItems = Number(res.data.data.total_items);
          let restTotal = totalItems - packages.length;

          if (packages.length == 0) {
            toast.error(
              `Building doesn't have facility, please select other building`
            );
          } else {
            let formatted = packages.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (packages.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setFacilities(formatted);
          }
        }
      )
    );
  }, [dispatch, limit]);

  // Package Member Options based on Facility
  useEffect(() => {
    dispatch(
      get(
        endpointBookingFacility +
          "/admin/facilities/" +
          selectedFacility +
          "/packages",
        (res) => {
          let packages = res.data.data;

          let formatted = packages.map((el) => ({
            label: el.name,
            value: el.id.toString(),
          }));

          setPackages(formatted);
        }
      )
    );
  }, [dispatch, selectedFacility]);

  // ! There's no params building_id on this get endpoint.
  let filterBuilding = {
    hidex: buildingFilter == "",
    label: "Building: ",
    value: buildingFilter ? buildingFilter_label : "All",
    delete: () => {
      dispatch(
        setMembershipFilter({
          ...membershipFilter,
          buildingFilter: "",
          buildingFilter_label: "",
        })
      );
    },
    component: (toggleModal) => (
      <>
        <InputSearch
          label="Search Building"
          compact
          icon={<FiSearch />}
          inputValue={search}
          setInputValue={setSearch}
        />
        <Filter
          data={buildings}
          onClick={(el) => {
            if (!el.value) {
              setLimit(limit + el.restTotal);
              return;
            }
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                buildingFilter: el.value,
                buildingFilter_label: el.label,
              })
            );
            setSelectedBuilding(el.value);
            setLimit(5);
            toggleModal(false);
          }}
          onClickAll={() => {
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                buildingFilter: "",
                buildingFilter_label: "",
              })
            );
            setLimit(5);
            toggleModal(false);
            setPackages([]);
          }}
        />
      </>
    ),
  };

  let filterFacility = {
    hidex: facilityFilter == "",
    label: "Facility: ",
    value: facilityFilter ? facilityFilter_label : "All",
    delete: () => {
      dispatch(
        setMembershipFilter({
          ...membershipFilter,
          facilityFilter: "",
          facilityFilter_label: "",
          packageFilter: "",
          packageFilter_label: "",
        })
      );
      setPackages([]);
    },
    component: (toggleModal) => (
      <>
        <InputSearch
          label="Search Facility"
          compact
          icon={<FiSearch />}
          inputValue={search}
          setInputValue={setSearch}
        />
        <Filter
          data={
            search
              ? facilites.filter((data) => {
                  return data.label
                    .toLowerCase()
                    .includes(search.toLowerCase());
                })
              : facilites
          }
          onClick={(el) => {
            if (!el.value) {
              setLimit(limit + el.restTotal);
              return;
            }
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                facilityFilter: el.value,
                facilityFilter_label: el.label,
              })
            );
            setSelectedFacility(el.value);
            setLimit(5);
            toggleModal(false);
          }}
          onClickAll={() => {
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                facilityFilter: "",
                facilityFilter_label: "",
              })
            );
            setLimit(5);
            toggleModal(false);
          }}
        />
      </>
    ),
  };

  let filterPackage = {
    hidex: packageFilter == "",
    label: "Package: ",
    value: packageFilter ? packageFilter_label : "All",
    delete: () => {
      dispatch(
        setMembershipFilter({
          ...membershipFilter,
          packageFilter: "",
          packageFilter_label: "",
        })
      );
    },
    component: (toggleModal) => (
      <>
        <InputSearch
          label="Search Facility"
          compact
          icon={<FiSearch />}
          inputValue={search}
          setInputValue={setSearch}
        />
        <Filter
          data={packages}
          onClick={(el) => {
            if (!el.value) {
              setLimit(limit + el.restTotal);
              return;
            }
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                packageFilter: el.value,
                packageFilter_label: el.label,
              })
            );
            setLimit(5);
            toggleModal(false);
          }}
          onClickAll={() => {
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                packageFilter: "",
                packageFilter_label: "",
              })
            );
            setLimit(5);
            toggleModal(false);
          }}
        />
      </>
    ),
  };

  let filterStatus = {
    hidex: statusFilter == "",
    label: "Status: ",
    value: statusFilter ? statusFilter_label : "All",
    delete: () => {
      dispatch(
        setMembershipFilter({
          ...membershipFilter,
          statusFilter: "",
          statusFilter_label: "",
        })
      );
    },
    component: (toggleModal) => (
      <>
        <Filter
          data={statusOptions}
          onClick={(el) => {
            if (!el.value) {
              setLimit(limit + el.restTotal);
              return;
            }
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                statusFilter: el.value,
                statusFilter_label: el.label,
              })
            );
            setLimit(5);
            toggleModal(false);
          }}
          onClickAll={() => {
            dispatch(
              setMembershipFilter({
                ...membershipFilter,
                statusFilter: "",
                statusFilter_label: "",
              })
            );
            setLimit(5);
            toggleModal(false);
          }}
        />
      </>
    ),
  };

  // If card data already has a value, it will return null
  useEffect(() => {
    if (cardData) {
      setCardData(null);
      dispatch(setIsLogin(false));
    }
  }, []);

  return (
    <>
      <h2 className="PageTitle mt-3">Activity</h2>
      <div className="flex gap-x-4 flex-1 w-full max-h-[100%] overflow-hidden">
        {/* Left Component */}
        <div className="Container flex flex-col max-w-[29rem] h-fit px-[1.5rem] pt-[2rem] shadow-none overflow-scroll">
          <div className="flex flex-col px-1 gap-y-1">
            <h1 className="font-bold text-[2rem]">Tap In Member</h1>
            <Formik
              initialValues={{ facility_id: "", card_number: "" }}
              autoComplete={"off"}
              onSubmit={(values, actions) => {
                setCardValid(false);
                setCardInvalid(false);
                dispatch(
                  checkingCardMember(
                    values,
                    setCardValid,
                    setCardInvalid,
                    setCardData
                  )
                );
              }}
            >
              {(props) => {
                const { values, setFieldValue, errors, handleSubmit } = props;

                return (
                  <Form>
                    <div className="flex flex-col gap-y-4 justify-center items-center mt-2">
                      <Input
                        {...props}
                        type="select"
                        star={true}
                        label="Facility"
                        autoComplete="off"
                        placeholder={"Select Facility"}
                        name="facility_id"
                        options={facilityOptions}
                        setInputValue={(e) =>
                          setFieldValue("facility_id", e.value)
                        }
                        className={
                          "outline-none focus:outline-red-600 cursor-pointer hover:bg-red-50/50 transition-all"
                        }
                      />

                      {/* if valid_status false, set to Invalid, and disbled checkin */}

                      {values.facility_id && (
                        <Input
                          {...props}
                          label="Access Card Number"
                          className={
                            "outline-none focus:outline-red-600 cursor-pointer"
                          }
                          autoComplete="off"
                          onClick={(e) => e.target.select()}
                          name={"card_number"}
                          placeholder={"Tap the card on the card reader"}
                          setInputValue={(e) => setFieldValue("card_number", e)}
                          star={true}
                          onKeyPress={(e) =>
                            e.key === "Enter" &&
                            (e.preventDefault(), handleSubmit())
                          }
                        />
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>

            <div className="w-full flex flex-col gap-y-3 pt-[0.7rem]">
              {isLogin ? (
                cardData?.valid_status ? (
                  <div className="w-full py-3 text-green-600 border-green-600 bg-[#E6FFF3] border-[1.5px] rounded-lg flex flex-col gap-y-1 justify-center items-center">
                    <h1 className="font-bold text-[1.3rem]">VALID</h1>
                    <h1 className="text-[12px]">
                      Kartu dapat digunakan pada fasilitas ini
                    </h1>
                  </div>
                ) : cardData?.member_status !== "Created" ||
                  cardData === undefined ? (
                  <div className="w-full py-3 text-red-600 border-red-600 bg-[#ffe6e6] border-[1.5px] rounded-lg flex flex-col gap-y-1 justify-center items-center">
                    <h1 className="font-bold text-[1.3rem]">INVALID</h1>
                    <h1 className="text-[12px]">
                      {member_selected?.valid_message ||
                        "Kartu tidak dapat digunakan pada fasilitas ini"}
                    </h1>
                  </div>
                ) : (
                  <div className="w-full py-3 text-red-600 border-red-600 bg-[#ffe6e6] border-[1.5px] rounded-lg flex flex-col gap-y-1 justify-center items-center">
                    <h1 className="font-bold text-[1.3rem]">INVALID</h1>
                    <h1 className="text-[12px]">
                      {member_selected?.valid_message ||
                        "Kartu tidak dapat digunakan pada fasilitas ini"}
                    </h1>
                  </div>
                )
              ) : null}

              <div className="bg-gray-100 flex justify-center items-center h-[22rem] w-full rounded-lg overflow-hidden">
                {isLogin ? (
                  <img
                    src={member_selected?.image || DummyPhoto}
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <img
                    src={DummyPhoto}
                    className="object-cover w-full h-full"
                  />
                )}
              </div>

              {isLogin && (
                <>
                  <div className="w-full h-fit flex flex-col px-1">
                    <div className="w-full flex justify-between border-b-[1px] pb-3 items-center">
                      <div className="flex flex-col gap-y-2 text-left">
                        <h1 className="font-bold text-[1.4rem] capitalize">
                          {member_selected?.fullname}
                        </h1>
                        <h1 className="text-[10px] text-gray-400">
                          Card Number: <b>{member_selected?.card_number}</b>
                        </h1>
                      </div>
                      {member_selected?.member_status == "Active" ? (
                        <Pill color={"success"}>
                          {member_selected?.member_status}
                        </Pill>
                      ) : member_selected?.member_status == "Paid" ? (
                        <Pill color={"info"}>
                          {member_selected?.member_status}
                        </Pill>
                      ) : member_selected?.member_status == "Created" ? (
                        <Pill color={"secondary"}>
                          {member_selected?.member_status}
                        </Pill>
                      ) : member_selected?.member_status == "Premium" ? (
                        <Pill color={"premium"}>
                          {member_selected?.member_status}
                        </Pill>
                      ) : (
                        <Pill color={"warning"}>
                          {member_selected?.member_status}
                        </Pill>
                      )}
                    </div>

                    {member_selected?.member_status !== "Premium" && (
                      <div className="flex w-full pt-3 gap-x-3 items-center justify-between">
                        <div className="flex flex-col gap-y-2 items-start justify-start text-left text-[13px]">
                          <h1 className="text-gray-400 text-[10px]">
                            Package & Period
                          </h1>
                          <h1 className="font-bold">
                            {member_selected?.package || "-"} -{" "}
                            {member_selected?.period || "-"}
                          </h1>
                        </div>
                        <div className="flex flex-col gap-y-2 items-start justify-start text-left text-[13px]">
                          <h1 className="text-gray-400 text-[10px]">
                            Valid Thru
                          </h1>
                          <h1 className="font-bold">
                            {member_selected?.expired_date
                              ? dateFormaterEx(member_selected?.expired_date)
                              : "-"}
                          </h1>
                        </div>
                      </div>
                    )}
                  </div>

                  <MyButton
                    label={
                      member_selected?.activity_status == "Check-in"
                        ? "Check Out"
                        : "Check In"
                    }
                    btnType={"submit"}
                    disabled={cardData?.valid_status ? false : true}
                    className={"w-full text-[14px]"}
                    onClick={() => {
                      dispatch(setModalReset());
                      member_selected?.activity_status == "Check-in"
                        ? dispatch(
                            setModalMembership({ checkoutMembership: true })
                          )
                        : dispatch(
                            setModalMembership({ checkinMembership: true })
                          );
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* Right Component */}
        <div className="Container flex flex-col w-[70%] p-[2rem]">
          <div className="flex flex-col px-1 gap-y-2 mb-4">
            <h1 className="font-bold text-[2rem]">Registered Member</h1>
            <h1 className="text-gray-400">
              Informasi data user yang sudah terdaftar sebagai membership
            </h1>
          </div>
          <TableMembership
            columns={membershipColumns}
            totalItems={total_items}
            data={items}
            loading={loading}
            pageCount={total_pages}
            filters={
              packages.length == 0
                ? [filterFacility, filterStatus]
                : [filterFacility, filterPackage, filterStatus]
            }
            filterVars={[facilityFilter, packageFilter, statusFilter]}
            fetchData={useCallback(
              (pageIndex, pageSize, search = "") => {
                dispatch(
                  get(
                    endpointBookingFacility +
                      "/admin/memberships?page=" +
                      (pageIndex + 1) +
                      "&limit=" +
                      pageSize +
                      "&search=" +
                      search +
                      "&facility_id=" +
                      facilityFilter +
                      "&package_id=" +
                      packageFilter +
                      "&status=" +
                      statusFilter,
                    (res) => {
                      dispatch(setData(res.data.data));
                    }
                  )
                );
              },
              [
                dispatch,
                total_items,
                total_pages,
                search,
                facilityFilter,
                packageFilter,
                statusFilter,
                refreshToggle,
              ]
            )}
            actionDownloads={
              canAdd
                ? [
                    <MyButton
                      key="Add Member"
                      label="Add Members"
                      icon={<FiPlus />}
                      onClick={() => {
                        dispatch(setModalReset());
                        dispatch(setModalMembership({ addMembership: true }));
                      }}
                    />,
                  ]
                : []
            }
            onClickDelete={(row) => {
              if (canDelete) {
                dispatch(setModalReset());
                dispatch(setModalMembership({ deleteMembership: true }));
                dispatch(setSelected({ selected: row }));
              }
            }}
            onClickEdit={(row) => {
              if (canUpdate) {
                dispatch(editMembershipForm(row.id, row));
                // dispatch(setModalMembership({ editMembership: true }));
              }
            }}
            onClickSetAsPaid={(row) => {
              if (canUpdate) {
                dispatch(setModalReset());
                dispatch(setModalMembership({ setAsPaid: true }));
                dispatch(setSelected({ selected: row }));
              }
            }}
            // onClickSeeHistory={(row) => {
            //   dispatch(setModalReset());
            //   dispatch(setModalMembership({ seeHistory: true }));
            //   dispatch(setSelected({ selected: row }));
            // }}
            onClickInactive={(row) => {
              dispatch(setModalReset());
              dispatch(setModalMembership({ setInactiveMembership: true }));
              dispatch(setSelected({ selected: row }));
            }}
            onClickActiveMember={(row) => {
              dispatch(setModalReset());
              dispatch(getDetailMembership(row.id));
              dispatch(setModalMembership({ activeMembership: true }));
              dispatch(setSelected({ selected: row }));
            }}
            onClickExtendMember={(row) => {
              dispatch(setModalReset());
              dispatch(getDetailMembership(row.id));
              dispatch(setModalMembership({ extendMembership: true }));
              dispatch(setSelected({ selected: row }));
            }}
            onClickChangeCardNumber={(row) => {
              dispatch(setModalReset());
              dispatch(getDetailMembership(row.id));
              dispatch(setModalMembership({ changeCardNumber: true }));
              dispatch(setSelected({ selected: row }));
            }}
            onClickReActiveMember={(row) => {
              dispatch(setModalReset());
              dispatch(getDetailMembership(row.id));
              dispatch(setModalMembership({ reActivateMembership: true }));
              dispatch(setSelected({ selected: row }));
            }}
          />
        </div>
      </div>

      {/* Calling All */}
      <ModalConfirmation
        setCardValid={setCardValid}
        setCardInvalid={setCardInvalid}
      />
      <ModalCreateMembership />
    </>
  );
}

export default Component;
