import React from "react";

function Component({ color, children }) {
  let colorBackground = {
    "": "bg-gray-600 text-white",
    success: "bg-green-600 text-white",
    secondary: "bg-gray-600 text-white",
    danger: "bg-red-600 text-white",
    info: "bg-blue-600 text-white",
    none: "bg-gray-600 text-white",
    online: "bg-lime-600 text-white",
    warning: "bg-red-600 text-white",
    light: "bg-gray-100 text-gray-800",
    primary: "bg-orange-600 text-white",
    premium: "bg-yellow-500 text-white",
  };

  return (
    <>
      <div
        className={`px-2.5 py-1 rounded-xl font-bold text-[12px] w-fit flex justify-center items-center ${
          color ? colorBackground[color] : ""
        }`}
      >
        {children}
      </div>
    </>
  );
}

export default Component;
