import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Table from "../../../../../components/Table";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../features/form/inputBooking";
import Pill from "../../../../../components/Pill";
import { Formik, Form } from "formik";
import MyButton from "../../../../../components/Button";
import ToggleSwitch from "../../../../../components/ToggleSwitch";
import { editSchedule } from "../../../../slices/facility";

function convertTo12Hour(time) {
  let [hours, minutes] = time.split(":").map(Number);
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
}

const columns = [
  { Header: "Day", accessor: (row) => row.day },
  { Header: "Open Time", accessor: (row) => convertTo12Hour(row.open_time) },
  { Header: "Closed Time", accessor: (row) => convertTo12Hour(row.close_time) },
  { Header: "Duration", accessor: (row) => row.duration },
  {
    id: "quota",
    Header: () => (
      <div>
        Quota <br /> Per Duration
      </div>
    ),
    accessor: (row) => row.quota_per_duration,
  },
  {
    Header: "Status",
    accessor: (row) => (
      <div>
        {row.is_available ? (
          <Pill color={"success"}>Open</Pill>
        ) : (
          <Pill color={"warning"}>Close</Pill>
        )}
      </div>
    ),
  },
];

function Component({ view, schedule }) {
  const [edit, setEdit] = useState(false);
  const [rowSchedule, setRowSchedule] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { group } = user;
  const { selected } = useSelector((state) => state.facility);

  let dispatch = useDispatch();

  return (
    <div>
      {/* Modal Edit Schedule */}
      <Modal
        isOpen={edit}
        toggle={() => setEdit(false)}
        title={"Edit Schedule"}
        disableFooter={true}
      >
        <Formik
          initialValues={rowSchedule}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            let newData = { ...selected };
            newData.operational_hour = newData.operational_hour.map((data) =>
              data.day === values.day ? values : data
            );
            dispatch(editSchedule(newData, setEdit, selected.id));
            setEdit(false);
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-2.5 flex flex-col gap-y-3 font-bold items-start py-3 mt-4 mb-4 bg-gray-100 rounded-xl">
                  <div className="w-full flex items-center justify-between px-2 border-b-[1px] pb-4">
                    <h1 className="text-[16px]">{rowSchedule?.day}</h1>
                    <div className="flex gap-x-2 items-center">
                      <ToggleSwitch
                        onFunction={() => setFieldValue("is_available", true)}
                        offFunction={() => setFieldValue("is_available", false)}
                        conditional={values.is_available}
                      />
                      <h1>{values.is_available ? "Open" : "Close"}</h1>
                    </div>
                  </div>
                  <div className="flex flex-col gap-y-4 w-full px-2">
                    <div className="w-full flex gap-x-4 rounded-lg">
                      <Input
                        {...props}
                        type="time"
                        name="open_time"
                        label="Open Time"
                        setInputValue={(e) => setFieldValue("open_time", e)}
                      />
                      <Input
                        {...props}
                        type="time"
                        label="Close Time"
                        name="close_time"
                        setInputValue={(e) => setFieldValue("close_time", e)}
                      />
                    </div>
                    <Input
                      {...props}
                      label="Quota Member"
                      name={`quota_per_duration`}
                      placeholder="e.g. 1"
                      type="number"
                    />
                    <Input
                      {...props}
                      label="Duration (hour)"
                      name={`duration`}
                      placeholder="e.g. 1"
                      type="number"
                    />
                  </div>
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Cancel"}
                    btnType={"button"}
                    onClick={() => setEdit(false)}
                    color={"secondary"}
                  />
                  <MyButton label={"Yes, Confirm"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      <Table
        noContainer={true}
        columns={columns}
        data={schedule}
        pageCount={1}
        noSearch={true}
        filters={[]}
        onClickEdit={
          (row) => {
            setRowSchedule(row);
            setEdit(true);
          }
          // view
          //   ? null
          //   : group === "vas_advertiser"
          //   ? null
        }
      />
    </div>
  );
}

export default Component;
