import { BsCardImage } from "react-icons/bs";
import React, { useState } from "react";
import Loading from "../../../components/Loading";
import Tab from "../../../components/Tab";
import Breadcrumb2 from "../../../components/Breadcrumb2";
import Modal from "../../../components/Modal";

const ImageEmpty = () => {
  return (
    <div className="w-full bg-gray-200 flex items-center justify-center rounded-xl">
      <BsCardImage className="size-16 text-gray-500" />
    </div>
  );
};

function Component({
  thumbnail,
  images = [{}],
  title,
  transparent = false,
  labels,
  contents,
  activeTab,
  pagetitle = "",
  loading = true,
}) {
  const [imgLoading, setImgLoading] = useState(true);
  const [modalPicture, setModalPicture] = useState(false);
  const [pictureUrl, setPictureUrl] = useState("");

  return (
    <>
      <Breadcrumb2 title={title ? title : "Details"} />
      <h2 className="PageTitle">{pagetitle}</h2>
      {/* <Loading loading={loading}> */}
      <div className="flex gap-x-4 w-full max-h-[100%] flex-1 overflow-hidden">
        {/* Left Component */}
        <div className="flex flex-col w-[31rem] h-fit transition-all">
          <div className="Container flex flex-col gap-y-3">
            <img
              src={thumbnail}
              className="rounded-xl object-cover h-full w-full cursor-pointer hover:scale-105 transition-all"
              onClick={() => {
                setPictureUrl(thumbnail);
                setModalPicture(true);
              }}
            />
            <div className="grid grid-cols-3 w-full h-[10rem] gap-x-3">
              {Array(3)
                .fill(null)
                .map((_, index) => {
                  const data = images[index];
                  return data?.url ? (
                    <img
                      key={index}
                      src={data?.url}
                      className="rounded-xl object-cover h-full w-full cursor-pointer hover:scale-105 transition-all"
                      onClick={() => {
                        setPictureUrl(data?.url);
                        setModalPicture(true);
                      }}
                    />
                  ) : (
                    <ImageEmpty key={index} />
                  );
                })}
            </div>
          </div>
        </div>

        {/* Right Component */}
        <div
          className={
            transparent
              ? "Container-transparent overflow-hidden flex-1"
              : "Container-dashboard-ns overflow-hidden flex-1"
          }
        >
          <Tab labels={labels} contents={contents} activeTab={activeTab} />
        </div>
      </div>

      {/* Modal Picture */}
      <Modal
        isOpen={modalPicture}
        toggle={() => setModalPicture(false)}
        title={"Facility Picture"}
        disableFooter={true}
      >
        <div className="rounded-xl overflow-hidden mt-4 h-fit">
          <img
            src={pictureUrl || "No Image Load"}
            className="w-full h-full object-cover"
          />{" "}
        </div>
      </Modal>
    </>
  );
}

export default Component;
