import { createSlice } from "@reduxjs/toolkit";
import { endpointBookingFacility } from "../../settings";
import { get, post, put, del, setInfo, getFile } from "../slice";
import { toast } from "sonner";
import { setFacilityOptions } from "./membership";

export const slice = createSlice({
  name: "facility",
  initialState: {
    loading: false,
    items: [],
    selected: {},
    selected_package: {},
    total_items: 0,
    total_pages: 1,
    page: 1,
    range: 10,
    refreshToggle: true,
    items_membership: {
      items: [],
      total_items: 0,
      total_pages: 1,
    },
    formAddFacility: {},
  },
  reducers: {
    startAsync: (state) => {
      state.loading = true;
    },
    stopAsync: (state) => {
      state.loading = false;
    },
    setData: (state, action) => {
      const data = action.payload;

      state.items = data.items;
      state.total_items = data.filtered_item;
      state.total_pages = data.filtered_page;
    },
    setDataItemsMembership: (state, action) => {
      const data = action.payload;

      state.items_membership.items = data.items;
      state.items_membership.total_items = data.filtered_item;
      state.items_membership.total_pages = data.filtered_page;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    setSelectedPackage: (state, action) => {
      state.selected_package = action.payload;
    },
    refresh: (state) => {
      state.refreshToggle = !state.refreshToggle;
    },
  },
});

export const createFacility = (data, history) => (dispatch, getState) => {
  dispatch(startAsync());
  const { auth } = getState();

  dispatch(
    post(
      endpointBookingFacility + "/admin/facilities",
      data,
      (res) => {
        history.push("/" + auth.role + "/facility");
        toast.success("Facility has been created.");
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const createMembershipPackage =
  (data, setModal, id, history) => (dispatch, getState) => {
    dispatch(startAsync());
    const { auth } = getState();

    dispatch(
      post(
        endpointBookingFacility + "/admin/facilities/" + id + "/packages",
        data,
        (res) => {
          dispatch(getFacilityDetail(id, history));
          dispatch(getMembershipFacility(id));
          toast.success("Membership Package has been created.");
          setModal(false);
          dispatch(stopAsync());
        },
        (err) => {
          toast.error(err?.response?.data?.error);
          dispatch(stopAsync());
        }
      )
    );
  };

export const editFacility = (data, history, id) => (dispatch, getState) => {
  dispatch(startAsync());
  const { auth } = getState();

  dispatch(
    put(
      endpointBookingFacility + "/admin/facilities/" + id,
      data,
      (res) => {
        dispatch(setSelected(data));
        toast.success("Facility has been Updated.");
        history.push("/" + auth.role + "/facility/" + id);
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const editSchedule = (data, setModal, id) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    put(
      endpointBookingFacility + "/admin/facilities/" + id,
      data,
      (res) => {
        toast.success("Schedule has been updated");
        dispatch(setSelected(data));
        setModal(false);
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const deleteFacility = (id) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    del(
      endpointBookingFacility + "/admin/facilities/" + id,
      (res) => {
        dispatch(refresh());
        toast.success("Facility has been deleted.");
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(refresh());
        dispatch(stopAsync());
      }
    )
  );
};

export const deleteMembershipPackage =
  (id, setModal, selectedId) => (dispatch) => {
    dispatch(startAsync());

    dispatch(
      del(
        endpointBookingFacility +
          "/admin/facilities/" +
          id +
          "/packages/" +
          selectedId,
        (res) => {
          dispatch(getMembershipFacility(id));
          toast.success("Membership Package has been deleted.");
          setModal(false);
          dispatch(stopAsync());
        },
        (err) => {
          toast.error("Error: " + err?.response?.data?.error);
          dispatch(getMembershipFacility(id));
          dispatch(stopAsync());
        }
      )
    );
  };

export const getAllFacilities = (page, limit) => (dispatch) => {
  dispatch(
    get(
      endpointBookingFacility +
        "/admin/facilities" +
        "?page=" +
        (page + 1) +
        "&limit=" +
        limit,
      (res) => {
        dispatch(setFacilityOptions(res.data.data.items));
      }
    )
  );
};

export const getMembershipFacility = (id) => (dispatch) => {
  dispatch(
    get(
      endpointBookingFacility + "/admin/facilities/" + id + "/packages",
      (res) => {
        dispatch(setSelectedPackage(res.data.data));
      },
      (err) => {
        toast.error(err?.response?.data?.error);
      }
    )
  );
};

export const getFacilityDetail = (id, history) => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(
    get(
      endpointBookingFacility + "/admin/facilities/" + id,
      (res) => {
        dispatch(setSelected(res.data.data));
        history.push("/" + auth.role + "/facility/" + id);
        dispatch(stopAsync());
      },
      (err) => {
        toast.error(err?.response?.data?.error);
        dispatch(stopAsync());
      }
    )
  );
};

export const toFacilityDetail = (id, history, edit) => (dispatch, getState) => {
  const { auth } = getState();
  dispatch(startAsync());
  if (edit) {
    history.push("/" + auth.role + "/facility/edit");
    dispatch(stopAsync());
  } else {
    dispatch(getMembershipFacility(id));
    dispatch(getFacilityDetail(id, history));
    dispatch(stopAsync());
  }
};

export const {
  startAsync,
  stopAsync,
  setData,
  setSelected,
  setSelectedPackage,
  refresh,
  setDataItemsMembership,
} = slice.actions;

export default slice.reducer;
