import { AiOutlineRight } from "react-icons/ai";
import React, { useEffect, useCallback, useState } from "react";
import { FiX, FiSearch, FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";

import Button from "../../../../components/Button";
import Table from "../../../../components/Table";
import {
  createResidentMembership,
  getResidentMembership,
} from "../../../slices/resident";
import { dateTimeFormatter } from "../../../../utils";
import Modal from "../../../../components/Modal";
import MyButton from "../../../../components/Button";
import { Form, Formik } from "formik";
import { updateDeleteMembership } from "../../../slices/membership";
import ModalSuccessMembership from "../../Membership/components/Modal/ModalSuccessMembership";
import { get } from "../../../slice";
import { endpointAdmin, endpointBookingFacility } from "../../../../settings";
import Input from "../../../form/inputBooking";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";

const columnsMembership = [
  { Header: "Facility", accessor: (row) => <p>{row.facility}</p> },
  { Header: "Card Number", accessor: (row) => <p>{row.card_number}</p> },
  {
    Header: "Created On",
    accessor: (row) => <p>{dateTimeFormatter(row.created_at)}</p>,
  },
];

const formPayload = {
  facility_id: "",
  card_number: "",
  building_id: "",
};

function Component({ id, view, canAdd, canUpdate, canDelete }) {
  const { user, role } = useSelector((state) => state.auth);

  const [modalAddMembership, setModalAddMembership] = useState(false);
  const [modalDeleteMembership, setModalDeleteMembership] = useState(false);
  const [selectedRow, setSelectedRow] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [facilites, setFacilities] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState(
    role === "bm" ? user?.building_id : ""
  );
  const [nullFacility, setNullFacility] = useState(false);
  const { id: residentId } = useParams();

  const { membership, loading, refreshToggle } = useSelector(
    (state) => state.resident
  );
  let dispatch = useDispatch();
  let history = useHistory();

  // Fetch Data for Callback each Table Data
  const fetchData = useCallback(
    (pageIndex, pageSize) => {
      dispatch(getResidentMembership(pageIndex, pageSize, id));
    },
    [dispatch, refreshToggle, id]
  );

  // Building Options
  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/management/building" +
          "?limit=10&page=1" +
          "&search=",
        (res) => {
          let buildings = res.data.data.items;

          let formatted = buildings.map((el) => ({
            label: el.building_name,
            value: el.building_id.toString(),
          }));

          setBuildings(formatted);
        }
      )
    );
  }, [dispatch]);

  // Facility Options based on Building ID
  useEffect(() => {
    setFetchLoading(true);
    dispatch(
      get(
        endpointBookingFacility +
          "/admin/facilities?building_id=" +
          selectedBuilding,
        (res) => {
          let packages = res.data.data.items;

          if (!packages) {
            setNullFacility(false);
            toast.error(
              `Building doesn't have facility, please select other building`
            );
            setFetchLoading(false);
          } else {
            let formatted = packages.map((el) => ({
              label: el.name,
              value: el.id.toString(),
            }));
            setNullFacility(true);
            setFacilities(formatted);
            setFetchLoading(false);
          }
        }
      )
    );
  }, [selectedBuilding, dispatch, formPayload]);

  useEffect(() => {
    dispatch(getResidentMembership(0, 10, id));
  }, [id]);

  return (
    <>
      {/* Modal Add Membership */}
      <Modal
        isOpen={modalAddMembership}
        toggle={() => setModalAddMembership(false)}
        title={"Create Membership"}
        disableFooter={true}
      >
        <Formik
          initialValues={formPayload}
          autoComplete={"off"}
          onSubmit={(values, actions) => {
            let payload =
              role === "bm"
                ? {
                    ...values,
                    building_id: user?.building_id,
                    resident_id: parseInt(residentId),
                  }
                : { ...values, resident_id: parseInt(residentId) };

            dispatch(createResidentMembership(payload, setModalAddMembership));
          }}
        >
          {(props) => {
            const { values, setFieldValue, errors, handleSubmit } = props;

            return (
              <Form>
                <div className="px-3 flex flex-col gap-y-5 items-start py-4">
                  {role === "sa" && (
                    <Input
                      {...props}
                      autoComplete="off"
                      label="Building"
                      className={
                        "outline-none focus:outline-red-600 cursor-pointer"
                      }
                      placeholder={"Select Building"}
                      name="building_id"
                      onChange={(e) => {
                        setFieldValue("building_id", parseInt(e.value));
                        setSelectedBuilding(e.value);
                      }}
                      options={buildings}
                      star={true}
                    />
                  )}

                  {(values?.building_id && nullFacility) ||
                    (role === "bm" && (
                      <Input
                        {...props}
                        autoComplete="off"
                        label="Facility"
                        className={
                          "outline-none focus:outline-red-600 cursor-pointer"
                        }
                        placeholder={"Select Facility"}
                        name="facility_id"
                        onChange={(e) => {
                          setFieldValue("facility_id", e.value);
                        }}
                        options={facilites}
                        star={true}
                      />
                    ))}

                  <Input
                    {...props}
                    label="Card Number"
                    className={
                      "outline-none focus:outline-red-600 cursor-pointer"
                    }
                    autoComplete="off"
                    onClick={(e) => e.target.select()}
                    name={"card_number"}
                    placeholder={"Tap the card on the card reader"}
                    setInputValue={(e) => setFieldValue("card_number", e)}
                    star={true}
                    onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  />
                </div>

                <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
                  <MyButton
                    label={"Back"}
                    btnType={"button"}
                    onClick={() => setModalAddMembership(false)}
                    color={"secondary"}
                  />
                  <MyButton label={"Create"} btnType={"submit"} />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>

      {/* Modal Delete Membership */}
      <Modal
        isOpen={modalDeleteMembership}
        toggle={() => setModalDeleteMembership(false)}
        title={"Delete Memberhsip"}
        disableFooter={true}
      >
        <div className="px-2">
          <p className="py-10">
            Are you sure you want to delete this membership? <br />
            All stored data will be automatically deleted by the system
          </p>
        </div>

        <div className="flex justify-end items-center w-full border-t-[1px] pt-3">
          <MyButton
            label={"Cancel"}
            btnType={"button"}
            onClick={() => setModalDeleteMembership(false)}
            color={"secondary"}
          />
          <MyButton
            label={"Yes, Confirm"}
            btnType={"button"}
            onClick={() => dispatch(updateDeleteMembership(selectedRow?.id))}
          />
        </div>
      </Modal>

      {canAdd && canUpdate && facilites?.length > 0 ? (
        <Button
          key="Add Membership"
          label="Add Membership"
          icon={<FiPlus />}
          onClick={() => setModalAddMembership(true)}
        />
      ) : (
        []
      )}

      {fetchLoading ? (
        <></>
      ) : (
        facilites.length === 0 && (
          <div
            onClick={() => history.push("/" + role + "/facility")}
            className="w-full rounded-lg bg-yellow-50 p-3 font-bold border-[1px] border-yellow-600 flex justify-between cursor-pointer hover:bg-yellow-600 hover:text-white transition-all"
          >
            <h1>
              You don't have facility. Please create your facility first here
            </h1>
            <AiOutlineRight />
          </div>
        )
      )}

      <Table
        noSearch={true}
        totalItems={membership?.total_items}
        noContainer={true}
        columns={columnsMembership}
        data={membership?.items || []}
        loading={loading}
        pageCount={membership?.total_pages}
        fetchData={fetchData}
        filters={[]}
        onClickDelete={
          canDelete
            ? (row) => {
                setModalDeleteMembership(true);
                setSelectedRow(row);
              }
            : []
        }
      />

      <ModalSuccessMembership />
    </>
  );
}

export default Component;
