import { IoIosCheckbox } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { GiTowel } from "react-icons/gi";
import { AiOutlineWifi } from "react-icons/ai";
import { FaToilet } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import { AiOutlinePlus } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get, post, setInfo } from "../slice";
import { endpointAdmin } from "../../settings";
import Template from "./components/TemplateWithFormikBook";
import { Form, FieldArray } from "formik";
import Input from "./inputBooking";
import SubmitButton from "./components/SubmitButton";
import { createFacility, editFacility } from "../slices/facility";
import MyButton from "../../components/Button";
import ToggleSwitch from "../../components/ToggleSwitch";
import { toast } from "sonner";

const facilityData = {
  building_id: "",
  thumbnail_url: "",
  image_urls: [{ url: "" }],
  name: "",
  location: "",
  description: "",
  check_in_start_minute: 0,
  other_facilities: [
    {
      additional_id: 1,
      additional_name: "toilet",
      is_check: false,
    },
    {
      additional_id: 2,
      additional_name: "towel",
      is_check: false,
    },
    {
      additional_id: 3,
      additional_name: "wifi",
      is_check: false,
    },
  ],
  rules: [""],
  operational_hour: [
    {
      is_available: false,
      day: "Sunday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: true,
      day: "Monday",
      open_time: "00:00",
      close_time: "23:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: true,
      day: "Tuesday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: true,
      day: "Wednesday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: true,
      day: "Thursday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: true,
      day: "Friday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
    {
      is_available: false,
      day: "Saturday",
      open_time: "00:00",
      close_time: "01:00",
      duration: null,
      quota_per_duration: null,
    },
  ],
};

function Component() {
  const { selected, loading } = useSelector((state) => state.facility);
  const { role, user } = useSelector((state) => state.auth);
  const { auth } = useSelector((state) => state);

  let dispatch = useDispatch();
  let history = useHistory();

  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    dispatch(
      get(
        endpointAdmin +
          "/management/building" +
          "?limit=10&page=1" +
          "&search=",
        (res) => {
          let buildings = res.data.data.items;

          console.log(buildings, "<<< values");

          let formatted = buildings.map((el) => ({
            // label: el.building_name + " by " + el.management_name,
            label: el.building_name,
            value: el.building_id.toString(),
          }));

          setBuildings(formatted);
        }
      )
    );
  }, [dispatch]);

  return (
    <div>
      <Template
        slice="facility"
        payload={
          selected?.image_urls?.length === 0
            ? { ...selected, image_urls: [{ url: "" }] }
            : selected?.id
            ? { ...selected }
            : facilityData
        }
        // schema={facilitySchema}
        formatValues={(values) => {
          const { ...facilityData } = values;
          return facilityData;
        }}
        edit={(data) => {
          data.building_id = data.building_id.toString();
          dispatch(editFacility(data, history, selected.id));
          // delete data[undefined];
        }}
        add={(data) => {
          if (auth.role === "bm") {
            data.building_id = user.building_id.toString();
            dispatch(createFacility(data, history));
            return;
            // delete data[undefined];
          }
          // delete data[undefined];
          dispatch(createFacility(data, history));
        }}
        renderChild={(props) => {
          const { values, errors, setFieldValue } = props;

          return (
            <Form className="w-full flex items-center justify-center py-4">
              <div className="w-[800px] flex justify-center items-center flex-col gap-y-8">
                {role === "sa" && (
                  <Input
                    {...props}
                    label="Building"
                    name="building_id"
                    autoComplete="off"
                    options={buildings}
                    onChange={(e) => setFieldValue("building_id", e.value)}
                  />
                )}

                <Input
                  {...props}
                  label="Facility Name"
                  name="name"
                  className="w-full"
                  autoComplete="off"
                />
                <Input
                  {...props}
                  label="Thumbnail Facility"
                  name="thumbnail_url"
                  type="file"
                  accept="image/*"
                  autoComplete="off"
                />

                {/* Imgae Urls */}
                <FieldArray
                  name="image_urls"
                  render={(arrayHelpers) => {
                    return (
                      <div className="w-full items-center flex flex-col pb-4 gap-y-7">
                        {values?.image_urls?.map((data, index) => {
                          return (
                            <div className="flex flex-col items-center relative w-full">
                              <Input
                                {...props}
                                label="Image Facilities"
                                name={`image_urls[${index}].url`}
                                type="file"
                                compact={index === 0 ? false : true}
                                accept="image/*"
                                actionButton={
                                  values?.image_urls.length < 3 &&
                                  values?.image_urls.length == index + 1 && (
                                    <MyButton
                                      btnType={"button"}
                                      onClick={() =>
                                        arrayHelpers.push({
                                          url: "",
                                        })
                                      }
                                      className={
                                        "active:scale-90 transition-all"
                                      }
                                      icon={<AiOutlinePlus />}
                                      label={"Add More Images"}
                                    />
                                  )
                                }
                                onChange={(e) =>
                                  setFieldValue(`image_urls[${index}].url`, e)
                                }
                              />

                              {index !== 0 && (
                                <BsTrash
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="absolute right-14 top-2 hover:bg-red-600 hover:text-white size-8 rounded-md p-1 cursor-pointer transition-all"
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />

                <Input
                  {...props}
                  label="Location"
                  name="location"
                  type="textarea"
                  autoComplete="off"
                />

                <Input
                  {...props}
                  label="Check In Start Minute"
                  name="check_in_start_minute"
                  type="number"
                  autoComplete="off"
                  placeholder="check_in_start_minute (must number)"
                />

                <Input
                  {...props}
                  label="Description"
                  name="description"
                  type="textarea"
                  autoComplete="off"
                />

                {/* Rules Array */}
                <FieldArray
                  name="rules"
                  render={(arrayHelpers) => {
                    return (
                      <div className="w-full items-center flex flex-col pb-4 gap-y-7">
                        {values?.rules?.map((data, index) => {
                          return (
                            <div
                              key={index}
                              className="flex flex-col items-center relative w-full"
                            >
                              <Input
                                {...props}
                                label="Rules"
                                autoComplete="off"
                                name={`rules[${index}]`}
                                compact={index === 0 ? false : true}
                                actionButton={
                                  values.rules.length == index + 1 && (
                                    <MyButton
                                      btnType={"button"}
                                      onClick={() => arrayHelpers.push("")}
                                      className={
                                        "active:scale-90 transition-all"
                                      }
                                      icon={<AiOutlinePlus />}
                                      label={"Add More Rules"}
                                    />
                                  )
                                }
                                onChange={(e) =>
                                  setFieldValue(`rules[${index}]`, e)
                                }
                              />

                              {index !== 0 && (
                                <BsTrash
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="absolute right-14 top-4 hover:bg-red-600 hover:text-white size-8 rounded-md p-1 cursor-pointer transition-all"
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />

                {/* Other Facilites */}
                <div className="flex flex-col items-start gap-y-3 transition-all w-[600px]">
                  <h1 className="font-bold border-b-[1px] pb-3 w-full text-left mb-2">
                    Other Facilities
                  </h1>
                  <div className="w-full grid grid-cols-3 gap-2">
                    <div
                      onClick={() =>
                        setFieldValue(
                          "other_facilities[0].is_check",
                          !values.other_facilities[0].is_check
                        )
                      }
                      className="flex gap-x-2 bg-gray-100 px-4 py-3 w-full items-center font-bold hover:bg-red-600 p-2 rounded-lg transition-all hover:text-white cursor-pointer group"
                    >
                      {values.other_facilities[0].is_check ? (
                        <IoIosCheckbox className="size-7 text-red-600 group-hover:text-white" />
                      ) : (
                        <MdCheckBoxOutlineBlank className="size-7" />
                      )}
                      <FaToilet />
                      <h1>Toilet</h1>
                    </div>
                    <div
                      onClick={() =>
                        setFieldValue(
                          "other_facilities[1].is_check",
                          !values.other_facilities[1].is_check
                        )
                      }
                      className="flex gap-x-2 bg-gray-100 px-4 py-3 w-full items-center font-bold hover:bg-red-600 p-2 rounded-lg transition-all hover:text-white cursor-pointer group"
                    >
                      {values.other_facilities[1].is_check ? (
                        <IoIosCheckbox className="size-7 text-red-600 group-hover:text-white" />
                      ) : (
                        <MdCheckBoxOutlineBlank className="size-7" />
                      )}
                      <AiOutlineWifi className="size-6" />
                      <h1>Wifi</h1>
                    </div>
                    <div
                      onClick={() =>
                        setFieldValue(
                          "other_facilities[2].is_check",
                          !values.other_facilities[2].is_check
                        )
                      }
                      className="flex gap-x-2 bg-gray-100 px-4 py-3 w-full items-center font-bold hover:bg-red-600 p-2 rounded-lg transition-all hover:text-white cursor-pointer group"
                    >
                      {values.other_facilities[2].is_check ? (
                        <IoIosCheckbox className="size-7 text-red-600 group-hover:text-white" />
                      ) : (
                        <MdCheckBoxOutlineBlank className="size-7" />
                      )}
                      <GiTowel className="size-6" />
                      <h1>Towel</h1>
                    </div>
                  </div>
                </div>

                {/* Operational Hour */}
                <div className="flex flex-col w-[600px] items-start gap-y-3 transition-all mt-4">
                  <h1 className="font-bold border-b-[1px] pb-3 w-full text-left mb-2">
                    Operational Hour
                  </h1>
                  {values?.operational_hour?.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          data?.is_available
                            ? "bg-gray-50"
                            : "bg-gray-100 text-gray-400"
                        } flex flex-col  p-4 w-full items-start rounded-lg gap-y-4 transition-all`}
                      >
                        <div className="flex w-full justify-between border-b-[1px] pb-3 items-center">
                          <h1 className="font-bold text-[17px]">{data?.day}</h1>
                          <div className="flex gap-x-2 font-bold items-center">
                            <h1 className="transition-all">
                              {data?.is_available ? "Open" : "Closed"}
                            </h1>
                            <ToggleSwitch
                              onFunction={() =>
                                setFieldValue(
                                  `operational_hour[${index}].is_available`,
                                  true
                                )
                              }
                              offFunction={() =>
                                setFieldValue(
                                  `operational_hour[${index}].is_available`,
                                  false
                                )
                              }
                              conditional={data?.is_available}
                            />
                          </div>
                        </div>

                        <div className="flex flex-row w-full items-center gap-y-1 gap-x-4">
                          <Input
                            {...props}
                            label="Open Time"
                            name={`operational_hour[${index}].open_time`}
                            type="time"
                          />
                          <h1 className="mt-8">to</h1>
                          <Input
                            {...props}
                            label="Close Time"
                            name={`operational_hour[${index}].close_time`}
                            type="time"
                          />
                          <Input
                            {...props}
                            label="Quota Member"
                            name={`operational_hour[${index}].quota_per_duration`}
                            className="w-[5rem]"
                            placeholder="e.g. 1"
                            type="number"
                          />
                          <Input
                            {...props}
                            label="Duration (hour)"
                            name={`operational_hour[${index}].duration`}
                            className="w-[5rem]"
                            placeholder="e.g. 1"
                            type="number"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>

                <SubmitButton loading={loading} errors={errors} />
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}

export default Component;
