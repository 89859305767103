import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { CgClose } from "react-icons/cg";

function Component({ isOpen, toggle, title, subtitle = "", content }) {
  const closeBtn = (
    <div
      onClick={toggle}
      className={`bg-gray-200 p-2 rounded-lg hover:bg-red-600 hover:text-white transition-all cursor-pointer ${
        subtitle ? "" : ""
      } `}
    >
      <CgClose />
    </div>
  );

  let history = useHistory();

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggle();

        if (title === "Token Expired") {
          history.push("/login");
          window.location.reload();
        }
      }}
      scrollable={false}
      modalTransition={{ timeout: 1 }}
      backdropTransition={{ timeout: 1 }}
    >
      <ModalHeader
        toggle={toggle}
        close={closeBtn}
        className={`w-full flex justify-between px-4 mt-2 ${
          subtitle ? "" : ""
        }`}
      >
        <h3 className="text-[20px] font-bold">{title}</h3>
      </ModalHeader>
      <ModalBody className="text-red-600 font-bold text-[14px] flex flex-col gap-y-1 px-4 bg-gray-100 rounded-lg m-4">
        {subtitle && <p className="text-left rounded-lg">{subtitle}</p>}
        <p className="text-red-500 text-left">Error Message : {content}</p>
      </ModalBody>
    </Modal>
  );
}

export default Component;
