import { createSlice } from "@reduxjs/toolkit";
import { endpointTransaction, endpointManagement } from "../../settings";
import { get, post, put, del, setInfo, getFile } from "../slice";
import moment from "moment";
import { toast } from "sonner";

const monthStart = moment().startOf("month").format("yyyy-MM-DD");
const monthEnd = moment().endOf("month").format("yyyy-MM-DD");
const today = moment().format("yyyy-MM-DD", "day");

export const slice = createSlice({
  name: "tokenthera",
  initialState: {
    loading: false,
    items: [],
    selected: {},
    total_items: 0,
    total_pages: 1,
    page: 1,
    range: 10,
    refreshToggle: true,
    theraSettlement: {
      items: [],
      selected: {},
      total_items: 0,
      total_pages: 1,
      settlement_amount: 0,
      unsettlement_amount: 0,
    },
    settlementFilter: {
      building_id: "",
      building_label: "",
      status: "",
      settlementStart: monthStart,
      settlementEnd: monthEnd,
      startDate: monthStart,
      endDate: monthEnd,
    },
  },
  reducers: {
    startAsync: (state) => {
      state.loading = true;
    },
    stopAsync: (state) => {
      state.loading = false;
    },
    setData: (state, action) => {
      const data = action.payload;

      state.items = data.items;
      state.total_items = data.filtered_item;
      state.total_pages = data.filtered_page;
    },
    setTheraSettlement: (state, action) => {
      const data = action.payload;

      state.theraSettlement.items = data.items;
      state.theraSettlement.total_items = data.total_item;
      state.theraSettlement.total_pages = data.total_page;
      state.theraSettlement.settlement_amount = data.settlement_amount;
      state.theraSettlement.unsettlement_amount = data.unsettlement_amount;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
    refresh: (state) => {
      state.refreshToggle = !state.refreshToggle;
    },
    setSettlementFilter: (state, action) => {
      state.settlementFilter = {
        building_id: "",
        building_label: "",
        status: "",
        settlementStart: monthStart,
        settlementEnd: monthEnd,
        startDate: monthStart,
        endDate: monthEnd,
        ...action.payload,
      };
    },
  },
});

export const editToken = (token, refCode, id) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    put(
      endpointTransaction + "/admin/thera/token",
      {
        token_code: token,
        token_ref_code: refCode,
        trx_code: id,
      },
      (res) => {
        dispatch(refresh());

        dispatch(
          setInfo({
            color: "success",
            message: "Token has been updated.",
          })
        );

        dispatch(stopAsync());
      },
      (err) => {
        dispatch(stopAsync());
      }
    )
  );
};

export const createMeter = (unitId, meterNumber) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    post(
      endpointManagement + "/admin/building-unit-meter",
      {
        unit_id: parseInt(unitId),
        meter_number: meterNumber,
      },
      (res) => {
        dispatch(
          setInfo({
            color: "success",
            message: "Token has been updated.",
          })
        );

        dispatch(stopAsync());
      },
      (err) => {
        dispatch(stopAsync());
      }
    )
  );
};

export const updateMeter = (unitId, meterNumber) => (dispatch) => {
  dispatch(startAsync());

  dispatch(
    put(
      endpointManagement + "/admin/building-unit-meter/update",
      {
        unit_id: parseInt(unitId),
        meter_number: meterNumber,
      },
      (res) => {
        dispatch(
          setInfo({
            color: "success",
            message: "Token has been updated.",
          })
        );

        dispatch(stopAsync());
      },
      (err) => {
        dispatch(stopAsync());
      }
    )
  );
};

export const getTheraSettlement =
  (pageIndex, pageSize, search = "", building, status, start = "", end = "") =>
  (dispatch) => {
    dispatch(startAsync());

    dispatch(
      get(
        endpointTransaction +
          "/admin/thera/settlement" +
          "?page=" +
          (pageIndex + 1) +
          "&limit=" +
          pageSize +
          "&building_id=" +
          building +
          "&settlement_status=" +
          status +
          "&date_min=" +
          start +
          "&date_max=" +
          end +
          "&search=" +
          search,

        (res) => {
          dispatch(setTheraSettlement(res.data.data));
          dispatch(stopAsync());
        },
        (err) => {
          dispatch(stopAsync());
        }
      )
    );
  };

export const downloadTheraSettlement =
  (
    pageIndex,
    pageSize = 1000,
    search = "",
    building,
    status,
    start = "",
    end = ""
  ) =>
  (dispatch) => {
    dispatch(startAsync());
    dispatch(
      getFile(
        endpointTransaction +
          "/admin/thera/settlement" +
          "?page=" +
          (pageIndex + 1) +
          "&limit=" +
          (pageSize + 999999) +
          "&search=" +
          search +
          "&building_id=" +
          building +
          "&settlement_status=" +
          status +
          "&date_min=" +
          start +
          "&date_max=" +
          end +
          "&export=true",
        `${start}_${end}_thera_settlement.csv`,
        (res) => {
          dispatch(stopAsync());
          toast.success("Success download your Settlement");
        },
        (err) => {
          dispatch(stopAsync());
        }
      )
    );
  };

export const {
  startAsync,
  stopAsync,
  setData,
  setSelected,
  refresh,
  setTheraSettlement,
  setSettlementFilter,
} = slice.actions;

export default slice.reducer;
