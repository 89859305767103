import React from "react";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getFile, postMultipart } from "../../slice";
import { endpointTransaction } from "../../../settings";
import exportFromJSON from "export-from-json";
import { toast } from "sonner";
import { refresh } from "../../slices/tokenthera";

const UploadSettlement = ({
  uploadModal,
  uploadResult,
  setUploadResult,
  setUploadModal,
  loading,
  loadingUpload,
  setLoadingUpload,
  fileUpload,
  setFileUpload,
  fileInput,
}) => {
  const templateLink = useSelector(
    (state) => state.auth.user.settlement_bulk_template
  );
  const dispatch = useDispatch();
  return (
    <Modal
      isOpen={uploadModal}
      toggle={() => {
        setUploadResult();
        setUploadModal(false);
      }}
      title="Upload Settlement"
      subtitle="Upload file type .xlsx for bulk settlement"
      okLabel={uploadResult ? "Download Error" : "Submit"}
      disablePrimary={loading}
      disableSecondary={loading}
      onClick={
        uploadResult
          ? () => {
              const formattedData = uploadResult?.map((item) => ({
                "Error Data": item,
              }));

              exportFromJSON({
                data: formattedData,
                fileName: "Error Data Upload Thera Settlement",
                exportType: exportFromJSON.types.xls,
              });

              setUploadResult("");
              setUploadModal(false);
            }
          : () => {
              setLoadingUpload(true);

              let formData = new FormData();
              formData.append("file", fileUpload);

              dispatch(
                postMultipart(
                  endpointTransaction +
                    "/admin/thera/settlement/bulksettlement",
                  formData,
                  (res) => {
                    if (res.data.data) {
                      setUploadResult(res.data.data);
                      toast.error("Error while uploading your data");
                      dispatch(refresh());
                    } else {
                      toast.success("Success uploading your data");
                      setUploadModal(false);
                      dispatch(refresh());
                    }
                    setLoadingUpload(false);
                  },
                  (err) => {
                    setLoadingUpload(false);
                  }
                )
              );
            }
      }
    >
      {uploadResult ? (
        <div style={{ maxHeight: "600px", overflow: "scroll" }}>
          <div className="flex flex-col w-full gap-y-1 bg-gray-100 rounded-xl max-h-[20rem] overflow-scroll p-4">
            <h1 className="font-bold">Some transactions code are errors:</h1>
            <ul className="pl-4 mt-2">
              {uploadResult?.slice(0, 6).map((data, index) => (
                <>
                  <li className="list-disc" key={index}>
                    {data}
                  </li>
                </>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        <Loading loading={loadingUpload}>
          <input
            className="d-block"
            ref={fileInput}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            type="file"
            onChange={(e) => {
              setFileUpload(fileInput.current.files[0]);
            }}
          />
          <button
            onClick={() => {
              setLoadingUpload(true);
              dispatch(
                getFile(
                  templateLink,
                  "billing_settlement_template.csv",
                  (res) => {
                    setLoadingUpload(false);
                  },
                  (err) => {
                    setLoadingUpload(false);
                  }
                )
              );
            }}
            style={{
              marginTop: 16,
              color: "white",
            }}
          >
            Download Template
          </button>
        </Loading>
      )}
    </Modal>
  );
};

export default UploadSettlement;
