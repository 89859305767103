import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { FiSearch, FiFile, FiDownload, FiHelpCircle } from "react-icons/fi";
import AnimatedNumber from "animated-number-react";
import moment from "moment";

import Table from "../../../components/Table";
import Breadcrumb from "../../../components/Breadcrumb";
import Button from "../../../components/Button";
import ButtonWizard from "../../../components/ButtonWizard";
import Input from "../../../components/Input";
import Filter from "../../../components/Filter";

import Pill from "../../../components/Pill";
import { endpointAdmin, endpointBilling } from "../../../settings";
import {
  toMoney,
  dateTimeFormatterCell,
  isRangeToday,
  toSentenceCase,
  isRangeThisMonth,
} from "../../../utils";
import { get } from "../../slice";
import DateRangeFilter from "../../../components/DateRangeFilter";
import ModalWizardBilling from "../../settlement/components/ModalWizardBilling";
import UploadSettlement from "../components/UploadSettlement";
import {
  downloadTheraSettlement,
  getTheraSettlement,
  setSettlementFilter,
} from "../../slices/tokenthera";

const formatValue = (value) => toMoney(value.toFixed(0));

function Component({ view, canUpdate, canDelete, canAdd }) {
  const { auth } = useSelector((state) => state);
  const {
    items,
    total_items,
    total_pages,
    settlement_amount,
    unsettlement_amount,
  } = useSelector((state) => state.tokenthera.theraSettlement);

  const { settlementFilter, refreshToggle, loading } = useSelector(
    (state) => state.tokenthera
  );

  const {
    building_id: building,
    building_label,
    status: settled,
    status_label,
    settlementStart,
    settlementEnd,
    startDate,
    endDate,
  } = useSelector((state) => state.tokenthera.settlementFilter);

  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(5);
  const [buildings, setBuildings] = useState("");
  const [info, setInfo] = useState({});
  const fileInput = useRef();
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadResult, setUploadResult] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [loadingUpload, setLoadingUpload] = useState(false);
  const today = moment().format("yyyy-MM-DD", "day");
  const monthStart = moment().startOf("month").format("yyyy-MM-DD");
  const monthEnd = moment().endOf("month").format("yyyy-MM-DD");
  const [openWizard, setOpenWizard] = useState(false);

  let dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        Header: "Trx Code",
        accessor: (row) => <b className="text-red-600">{row.trx_code}</b>,
      },
      {
        Header: "Unit",
        accessor: (row) => (
          <div className="flex flex-col gap-y-1 capitalize">
            <h1 className="font-bold">{row.unit_number}</h1>
            <h1>{row.section}</h1>
          </div>
        ),
      },
      { Header: "Building", accessor: "building_name" },
      { Header: "Meter Number", accessor: (row) => row.meter_number },
      {
        Header: "Amount",
        accessor: (row) => <div className="">{toMoney(row.amount)}</div>,
      },
      {
        Header: "Token",
        accessor: (row) => <div>{row.token_code || "-"}</div>,
      },
      {
        Header: "Status",
        accessor: (row) =>
          row.settlement_status === "SETTLED" ? (
            <Pill color="success">Settled</Pill>
          ) : (
            <Pill color="secondary">Unsettled</Pill>
          ),
      },
      {
        Header: "Settle Date",
        accessor: (row) =>
          row.settlement_date
            ? dateTimeFormatterCell(row.settlement_date)
            : "- ",
      },
    ],
    [auth]
  );

  useEffect(() => {
    (!search || search.length >= 1) &&
      dispatch(
        get(
          endpointAdmin +
            "/building" +
            "?limit=" +
            limit +
            "&page=1" +
            "&search=" +
            search,
          (res) => {
            let data = res.data.data.items;
            let totalItems = Number(res.data.data.total_items);
            let restTotal = totalItems - data.length;

            let formatted = data.map((el) => ({
              label: el.name,
              value: el.id,
            }));

            if (data.length < totalItems && search.length === 0) {
              formatted.push({
                label: "Load " + (restTotal > 5 ? 5 : restTotal) + " more",
                restTotal: restTotal > 5 ? 5 : restTotal,
                className: "load-more",
              });
            }

            setBuildings(formatted);
          }
        )
      );
  }, [dispatch, limit, search]);

  useEffect(() => {
    if (search.length === 0) setLimit(5);
  }, [search]);

  useEffect(() => {
    dispatch(
      get(endpointBilling + "/management/billing/statistic", (res) => {
        setInfo(res.data.data);
      })
    );
  }, [dispatch]);

  return (
    <div>
      <Breadcrumb title="Thera Settlement" />
      <h2 className="PageTitle">Token Thera Settlement</h2>

      <ModalWizardBilling
        openWizard={openWizard}
        setOpenWizard={setOpenWizard}
      />

      <UploadSettlement
        uploadModal={uploadModal}
        setUploadModal={setUploadModal}
        uploadResult={uploadResult}
        setUploadResult={setUploadResult}
        loading={loading}
        setLoadingUpload={setLoadingUpload}
        fileUpload={fileUpload}
        fileInput={fileInput}
        setFileUpload={setFileUpload}
        loadingUpload={loadingUpload}
      />

      <div className="flex bg-white shadow-yipy w-full px-5 py-4 rounded-xl mb-4 flex-1">
        <div className="flex flex-col flex-1">
          <div className="flex items-center gap-x-4 text-[16px]">
            <h1 className="font-bold">Unsettled Amount</h1>
            <AnimatedNumber
              className="BigNumber"
              value={unsettlement_amount}
              formatValue={formatValue}
            />
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex items-center gap-x-4 text-[16px]">
            <h1 className="font-bold">Settled Amount</h1>
            <AnimatedNumber
              className="BigNumber"
              value={settlement_amount}
              formatValue={formatValue}
            />
          </div>
        </div>
      </div>
      <div className="Container">
        <Table
          // onSelection={(selectedRows) => {
          //   setSelected(
          //     selectedRows.filter((el) => el && !el.payment_settled_date)
          //   );
          // }}
          totalItems={total_items}
          columns={columns}
          data={items}
          loading={loading}
          pageCount={total_pages}
          fetchData={useCallback(
            (pageIndex, pageSize, search) => {
              dispatch(
                getTheraSettlement(
                  pageIndex,
                  pageSize,
                  search,
                  building,
                  settled,
                  ...(auth.role === "sa"
                    ? [settlementStart, settlementEnd]
                    : auth.role === "bm"
                    ? [settlementStart, settlementEnd]
                    : [today, today])
                )
              );
              // eslint-disable-next-line react-hooks/exhaustive-deps
            },
            [
              dispatch,
              auth.role,
              refreshToggle,
              building,
              settled,
              settlementStart,
              settlementEnd,
              startDate,
              endDate,
            ]
          )}
          filters={
            auth.role === "sa"
              ? [
                  {
                    hidex: isRangeThisMonth(settlementStart, settlementEnd),
                    label: "Date: ",
                    delete: () => {
                      dispatch(
                        setSettlementFilter({
                          ...settlementFilter,
                          settlementStart: monthStart,
                          settlementEnd: monthEnd,
                        })
                      );
                    },
                    value: isRangeToday(settlementStart, settlementEnd)
                      ? "Today"
                      : moment(settlementStart).format("DD-MM-yyyy") +
                        " - " +
                        moment(settlementEnd).format("DD-MM-yyyy"),
                    component: (toggleModal) => (
                      <DateRangeFilter
                        startDate={monthStart}
                        endDate={monthEnd}
                        onApply={(start, end) => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              settlementStart: start,
                              settlementEnd: end,
                            })
                          );
                          toggleModal();
                        }}
                      />
                    ),
                  },
                  {
                    hidex: settled === "",
                    label: "Status: ",
                    value: settled ? status_label : "All",
                    delete: () => {
                      dispatch(
                        setSettlementFilter({
                          ...settlementFilter,
                          status: "",
                          status_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <Filter
                        data={[
                          { value: "UNSETTLED", label: "Unsettled" },
                          { value: "SETTLED", label: "Settled" },
                        ]}
                        onClick={(el) => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              status: el.value,
                              status_label: el.label,
                            })
                          );
                          toggleModal(false);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              status: "",
                              status_label: "",
                            })
                          );
                          toggleModal(false);
                        }}
                      />
                    ),
                  },
                  {
                    hidex: building === "",
                    label: "Building: ",
                    value: building ? building_label : "All",
                    delete: () => {
                      dispatch(
                        setSettlementFilter({
                          ...settlementFilter,
                          building_id: "",
                          building_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <>
                        <Input
                          label="Search"
                          compact
                          icon={<FiSearch />}
                          inputValue={search}
                          setInputValue={setSearch}
                        />
                        <Filter
                          data={buildings}
                          onClick={(el) => {
                            if (!el.value) {
                              setLimit(limit + el.restTotal);
                              return;
                            }
                            dispatch(
                              setSettlementFilter({
                                ...settlementFilter,
                                building_id: el.value,
                                building_label: el.label,
                              })
                            );
                            toggleModal(false);
                            setSearch("");
                          }}
                          onClickAll={() => {
                            dispatch(
                              setSettlementFilter({
                                ...settlementFilter,
                                building_id: "",
                                building_label: "",
                              })
                            );
                            toggleModal(false);
                            setSearch("");
                          }}
                        />
                      </>
                    ),
                  },
                ]
              : [
                  {
                    hidex: isRangeThisMonth(settlementStart, settlementEnd),
                    label: "Date: ",
                    delete: () => {
                      dispatch(
                        setSettlementFilter({
                          ...settlementFilter,
                          settlementStart: monthStart,
                          settlementEnd: monthEnd,
                        })
                      );
                    },
                    value: isRangeToday(settlementStart, settlementEnd)
                      ? "Today"
                      : moment(settlementStart).format("DD-MM-yyyy") +
                        " - " +
                        moment(settlementEnd).format("DD-MM-yyyy"),
                    component: (toggleModal) => (
                      <DateRangeFilter
                        startDate={monthStart}
                        endDate={monthEnd}
                        onApply={(start, end) => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              settlementStart: start,
                              settlementEnd: end,
                            })
                          );
                          toggleModal();
                        }}
                      />
                    ),
                  },
                  {
                    hidex: settled === "",
                    label: "Status: ",
                    value: settled ? status_label : "All",
                    delete: () => {
                      dispatch(
                        setSettlementFilter({
                          ...settlementFilter,
                          status: "",
                          status_label: "",
                        })
                      );
                    },
                    component: (toggleModal) => (
                      <Filter
                        data={[
                          { value: "UNSETTLED", label: "Unsettled" },
                          { value: "SETTLED", label: "Settled" },
                        ]}
                        onClick={(el) => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              status: el.value,
                              status_label: el.label,
                            })
                          );
                          toggleModal(false);
                        }}
                        onClickAll={() => {
                          dispatch(
                            setSettlementFilter({
                              ...settlementFilter,
                              status: "",
                              status_label: "",
                            })
                          );
                          toggleModal(false);
                        }}
                      />
                    ),
                  },
                ]
          }
          actionDownloads={
            view
              ? null
              : [
                  <ButtonWizard
                    color="Download"
                    icon={<FiHelpCircle style={{ fontSize: "16px" }} />}
                    onClick={() => {
                      setOpenWizard(true);
                    }}
                  />,
                ]
          }
          fetchDownload={useCallback(
            (pageIndex, pageSize, search) => {
              dispatch(
                downloadTheraSettlement(
                  pageIndex,
                  pageSize,
                  search,
                  building,
                  settled,
                  settlementStart,
                  settlementEnd
                )
              );
            },
            [dispatch, building, settled, settlementStart, settlementEnd]
          )}
          renderActions={(selectedRowIds, page) => {
            return [
              view
                ? null
                : auth.role === "sa" && (
                    <div style={{ display: "flex", columnGap: 3 }}>
                      <Button
                        onClick={() => {
                          setUploadModal(true);
                        }}
                        icon={<FiFile />}
                        label="Upload Settlement"
                      />
                    </div>
                  ),
            ];
          }}
        />
      </div>
    </div>
  );
}

export default Component;
