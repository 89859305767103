export const dataRequest = {
  items: [
    {
      id: 1,
      resident_name: "User Satu",
      resident_type: "Basic",
      facility_name: "Gym",
      package: "Platinum",
      period: "30 Day",
      status: "Booked",
      request_date: "2023-08-01",
      approved_date: null,
    },
    {
      id: 2,
      resident_name: "User Dua",
      resident_type: "Basic",
      facility_name: "Club House",
      package: "Platinum",
      period: "30 Day",
      status: "Approved",
      request_date: "2023-08-01",
      approved_date: "2023-08-01",
    },
  ],
  total_items: 2,
  filtered_page: 1,
  total_pages: 1,
  filtered_item: 10,
};

export const dataMembershipDummy = {
  message: "success",
  data: {
    items: [
      {
        id: 1,
        name: "adams idris",
        phone: "6287766554433",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "9891292323",
        status: "Created",
        expired_date: "2001-01-01T07:07:12+07:07",
      },
      {
        id: 2,
        name: " ",
        phone: "",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "",
        status: "Paid",
        expired_date: "0001-01-01T07:07:12+07:07",
      },
      {
        id: 4,
        name: "adams idris",
        phone: "6287766554433",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "9891292323",
        status: "Active",
        expired_date: "0001-01-01T07:07:12+07:07",
      },
      {
        id: 5,
        name: " ",
        phone: "",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "98912912323",
        status: "Inactive",
        expired_date: "0001-01-01T07:07:12+07:07",
      },
      {
        id: 6,
        name: " ",
        phone: "",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "989212912323",
        status: "Created",
        expired_date: "0001-01-01T07:07:12+07:07",
      },
      {
        id: 3,
        name: "adams idris",
        phone: "6287766554433",
        package_name: "Gold",
        package_period: 5,
        facility_name: "Lapangan Bola 2",
        card_number: "21rxxxxx123",
        status: "Active",
        expired_date: "2024-09-08T14:08:49.604002+07:00",
      },
      {
        id: 8,
        name: "adams idris",
        phone: "6287766554433",
        package_name: "Premium",
        package_period: 30,
        facility_name: "Lapangan Bola e",
        card_number: "9891292323",
        status: "Created",
        expired_date: "0001-01-01T07:07:12+07:07",
      },
    ],
    filtered_page: 1,
    filtered_item: 7,
    total_page: 1,
    total_item: 7,
  },
};

export const facilitiesOptions = [
  { label: "Club House", value: 1 },
  { label: "Gym", value: 2 },
];

export const statusOptions = [
  { label: "Created", value: "Created" },
  { label: "Paid", value: "Paid" },
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const packageOptions = [
  { label: "Platinum", value: "PLATINUM" },
  { label: "Gold", value: "GOLD" },
];

export const keyColorMembership = {
  Created: "secondary",
  Paid: "info",
  Active: "success",
  Inactive: "warning",
};

export const getDaysRemainingMembership = (dateString) => {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const timeDifference = targetDate - currentDate;
  const dayDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
  return `${Math.abs(dayDifference)} ${dayDifference === 1 ? "day" : "days"}`;
};
