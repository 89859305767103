import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { toFacilityDetail } from "../../features/slices/facility";

function Facilities({ items, id, data }) {
  const { role } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div
      onClick={() => dispatch(toFacilityDetail(id, history))}
      style={{ display: "block", textDecoration: "none", color: "#00000" }}
      className="Item"
    >
      {items.map((el, i) => (
        <div key={i}>{i === 0 ? <b>{el}</b> : <>{el}</>}</div>
      ))}
    </div>
  );
}

export default Facilities;
